.block-diy-tipps {
  position: relative;
  margin: 0 auto;
  padding-top: 22.5 * $base-width;
  padding-bottom: 20*$base-width;
  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    padding: 14.5rem 0 15*$base-width; }
  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    padding: 9.7rem 0 10*$base-width; }

  h2.big {
    top: -3px;
    z-index: 3;
    text-align: right;
    padding-right: 8%;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
      top: 52px; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      text-align: center;
      padding-right: 0; } }

  .inner-wrap {
    background: linear-gradient(256deg, #4D7F93 0%, #355460 100%) 0% 0% no-repeat padding-box;
    position: relative;
    .content-wrapper {
      display: flex;
      position: relative;
      justify-content: flex-end;
      max-width: 1475px;
      padding: 0 2.5*$base-width 0 0;
      margin: 0 auto;
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        flex-direction: column-reverse;
        padding: 0 2.5*$base-width; } }

    .row-text {
      padding: 16.25*$base-width 0;
      max-width: 695px;
      position: relative;
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        max-width: 100%;
        padding: 10*$base-width 0; }
      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 7.5*$base-width 0; }
      .arrow {
        position: absolute;
        left: -30%;
        top: 50%;
        transform: translateY(-50%);
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          display: none; } }
      .headline-wrapper {
        color: $light;
        h3 {
          color: $light; } }
      .text_field {
        color: $light;
        text-align: center; }
      .btn {
        margin: 0 auto;
        margin-top: 4*$base-width;
        svg {
          margin-right: 10px; }
        &:hover {
          svg {
            path {
              transition: $transition;
              fill: $primary; } } } }
      .zitat {
        position: relative;
        text-align: center;
        padding-top: 6*$base-width;
        margin-top: 6*$base-width;
        h4 {
          margin: 0 auto;
          max-width: 580px;
          color: $light;
          line-height: 1.6; }
        .zitatgeber {
          color: $light;
          font-style: italic;
          padding-top: 2.5*$base-width; }

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0%;
          left: 50%;
          transform: translateX(-50%);
          background-image: linear-gradient(to right, #C4C4C4 0%, #C4C4C4 50%, transparent 50%);
          background-size: 10px 2px;
          background-repeat: repeat-x; } } }

    .img_right {
      position: absolute;
      right: -6px;
      bottom: -7%;
      max-width: 250px;
      width: 100%;
      z-index: 10;
      @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        right: -50px;
        bottom: -5%;
        max-width: 200px; }
      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        display: none; } } }

  .video_slider {
    max-width: 500px;
    position: absolute;
    left: 0;
    top: -70px;
    transform: rotate(-4deg);
    opacity: 0;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
      top: -15px; }
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      max-width: 100%;
      padding-bottom: 20px; }
    .owl-stage-outer {
      overflow: hidden;
      .video-wrapper {
        justify-content: center;
        max-width: 500px;
        width: 100%;
        max-height: 900px;
        position: relative;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          max-width: 100%; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
          max-height: 650px; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          max-height: 450px;
          display: block; }
        .play-button {
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer; }
        video {
          width: 100%;
          height: 100%;
          object-fit: cover; } } }

    .owl-nav {
      position: absolute;
      top: 42%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        display: none; }
      svg {
        height: 100%; }
      button {
        border-radius: 50%;
        background-color: $light;
        width: 65px;
        height: 65px;
        border: 1px solid $dark;
        &.owl-prev {
          position: absolute;
          left: -7%; }
        &.owl-next {
          position: absolute;
          right: -7%; }
        &:hover {
          transition: $transition;
          background-color: $primary;
          border: 1px solid $primary;
          svg {
            path {
              transition: $transition;
              fill: $light; } } } } }

    .owl-dots {
      width: 100%;
      display: flex;
      margin-top: 30px;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        position: absolute; }
      .owl-dot {
        border-radius: 50%;
        border: 1px solid $primary;
        background-color: $light;
        width: 10px;
        height: 10px;
        margin: 0 5px; }
      .active {
        border: 1px solid $primary;
        background-color: $primary; } } }


  &.in-view {
    .row-text {
      animation: slide-in-text 1.5s ease forwards; }

    .video_slider {
      animation: slide-in-video 1.5s ease forwards; }

    @keyframes slide-in-text {
      from {
        margin-right: -25%;
        margin-left: 25%;
        opacity: 0; }

      to {
        margin-right: 0;
        margin-left: 0;
        opacity: 1; } }

    @keyframes slide-in-video {
        from {
          margin-left: -25%;
          margin-right: 25%;
          opacity: 0; }

        to {
          margin-left: 0;
          margin-right: 0;
          opacity: 1; } } } }

