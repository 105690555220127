/*Variables: Form * */

$success: green;
$error: red;

.block-form {
  .wrapper {
    max-width: $small;
    margin: 0 auto;
    padding: 0 2.5*$base-width;
    padding-bottom: 20*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding-bottom: 17.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-bottom: 15*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-bottom: 10*$base-width; }

    form {
      .form-inner {
        .group {
          display: flex;
          flex-wrap: wrap;
          .form-field-group {
            flex: 0 0 50%;
            width: 50%;
            &:first-child {
              padding-right: 15px; }
            &:last-child {
              padding-left: 15px;
              label {
                left: 3.5rem; } }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
              flex: 0 0 100%;
              width: 100%;
              &:first-child {
                padding-right: 0; }
              &:last-child {
                padding-left: 0;
                label {
                  left: 25px; } } } }
          &.cols-3 {
            .form-field-group {
              flex: 0 0 33.33%;
              width: 33.33%;
              &:first-child {
                padding-right: 15px; }
              &:last-child {
                padding-left: 5px;
                label {
                  left: 30px; } }
              @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%;
                width: 100%;
                &:first-child {
                  padding-right: 0; }
                &:last-child {
                  padding-left: 0;
                  label {
                    left: 25px; } } } } } }


        .group {
          .form-field-group {
            display: flex;
            flex: 0 0 50%;
            width: 50%;
            flex-direction: column;
            position: relative;
            padding-right: 10px;
            margin-top: 10px;
            margin-bottom: 10px;

            &:last-child {
              padding-left: 10px;
              padding-right: 0; }

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
              flex: 0 0 100%;
              flex-direction: column;
              width: 100%;
              padding-right: 0;
              &:last-child {
                padding-left: 0; } }

            input[type="text"], input[type="tel"], input[type="email"] {
              height: 60px;
              border: 1px solid #707070;
              border-radius: 0;
              padding: 10px 25px;
              font-size: 1.6rem;

              &.filled + label {
                transition:  $transition;
                top: -1px;
                font-size: 1.4rem;
                background-color: $light; }

              &:focus {
                outline: 0;
                + label {
                  transition:  $transition;
                  top: -1px;
                  font-size: 1.4rem;
                  background-color: $light; } } }

            label {
              position: absolute;
              pointer-events: none;
              top: 50%;
              transform: translateY(-50%);
              left: 25px;
              transition: all 0.3s ease-in-out;
              display: inline-block;
              padding-bottom: 0; } } }

        .form-field-group {
          position: relative;
          margin-top: 20px;
          margin-bottom: 20px;
          textarea {
            width: 100%;
            min-height: 15 * $base-width;
            border: 1px solid #707070;
            border-radius: 0;
            padding: 10px 25px;
            font-size: 1.6rem;
            &.filled + label {
              transition:  $transition;
              top: -1px;
              font-size: 1.4rem;
              background-color: $light; }

            &:focus {
              outline: 0;
              + label {
                transition:  $transition;
                top: -1px;
                font-size: 1.4rem;
                background-color: $light; } } }

          label {
            position: absolute;
            pointer-events: none;
            top: 16%;
            transform: translateY(-50%);
            left: 25px;
            transition: all 0.3s ease-in-out;
            display: inline-block;
            padding-bottom: 0; }

          &.full {
            display: flex;
            flex-direction: column;
            position: relative;
            padding-right: 0;
            margin-top: 10px;
            margin-bottom: 10px;

            &:last-child {
              padding-left: 10px;
              padding-right: 0; }

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
              &:last-child {
                padding-left: 0; } }

            input[type="text"] {
              height: 60px;
              border: 1px solid #707070;
              border-radius: 0;
              padding: 10px 25px;
              font-size: 1.6rem;

              &.filled + label {
                transition:  $transition;
                top: -1px;
                font-size: 1.4rem;
                background-color: $light; }

              &:focus {
                outline: 0;
                + label {
                  transition:  $transition;
                  top: -1px;
                  font-size: 1.4rem;
                  background-color: $light; } } }

            label {
              position: absolute;
              pointer-events: none;
              top: 50%;
              transform: translateY(-50%);
              left: 25px;
              transition: all 0.3s ease-in-out;
              display: inline-block;
              padding-bottom: 0; } }

          &.file-group {
            display: flex;
            flex-direction: row-reverse;
            justify-content: start;
            align-items: center;
            gap: 1rem;
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
              font-size: 1.5rem; }
            input[type="file"] {
              display: none; }

            .label-button {
              color: $dark;
              text-transform: uppercase;
              background-color: #fff;
              border-radius: 30px;
              display: inline-block;
              padding: 17px 30px 18px 30px;
              cursor: pointer;
              border: 1px solid $dark;
              transition: $transition;
              font-size: 1.6rem;
              line-height: 1;
              &:hover {
                background-color: $primary;
                border: 1px solid $primary;
                transition: all 0.3s;
                color: $light; } }

            label {
              position: relative;
              pointer-events: unset;
              top: unset;
              left: unset;
              transform: unset; }

            &:last-child {
              padding-left: 10px;
              padding-right: 0;
              label {
                left: 0; }
              @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding-left: 0; } } } }

        .checkbox {
          display: flex;

          #dsgvo {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: $base-width;
            margin-top: 6px;

            &.error {
              &::after {
                content: '';
                width: 25px;
                height: 25px;
                position: absolute;
                background-color: rgba($error,.25); }

              &:checked {
                &::after {
                  background-color: rgba($error,.0); } } } } } }

      #confirm {
        position: absolute;
        left: -20000px; }

      .form-success,
      .form-error {
        width: 100%;
        border: 2px solid $error;
        background-color: rgba($error,.2);
        padding: 3rem;
        margin-bottom: 3rem; }

      .form-success {
          border: 2px solid $success;
          background-color: rgba($success,.25); }

      .error {
        border-color: $error;
        background-color: rgba($error,.25); } }

    .btn.btn-secondary {
      margin-top: 20px;
      background-color: $light;
      &:hover {
        background-color: $primary; } } } }
