.block-banner {
    padding: 0;
    position: relative;

    picture {
        padding-top: 42vh;
        position: relative;
        display: block;
        width: 100%;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding-top: 30vh; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding-top: 38vh; }

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center; } }

    .content {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($primary, 0.9);
        display: flex;
        align-items: center;

        .inner {
            color: $light;
            text-align: center;
            h1, h2, h3 {
                color: $light; }
            .btn {
                margin: 0 auto;
                svg {
                    margin-right: 10px; }
                &:hover {
                    svg {
                        path {
                            transition: $transition;
                            stroke: $primary; }
                        #external-link {
                            path {
                                fill: $primary; } } } } } } } }
