.pre-list, .block.block-productlist:not(.block-productlist--ajax) .product-list-wrapper {
    // Künstlerliste
    position: relative;

    div.headline-wrapper, div.headline-wrapper.align-left {
        margin-right: 300px;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            margin-right: 240px; }

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            margin-right: 0; }

        h1, .h1, h2, .h2, h3, .h3 {
            display: flex;
            align-content: center;
            align-items: center;

            &:after {
                content: '';
                flex: 1;
                display: inline-block;
                width: auto;
                height: 0.1rem;
                position: relative;
                top: 50%;
                margin: 0 2*$base-width 0 3*$base-width;
                background: $dark;

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    display: none; } } } }

    .isotope.form-select {
        display: flex;
        padding: 0 0 0 2*$base-width;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5%;
        background: $light;
        border: 1px solid $dark;
        font-size: 1.4rem;
        text-transform: uppercase;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            position: relative;
            top: unset;
            transform: unset;
            right: unset;
            margin-bottom: 5*$base-width; }

        // @media only screen and (max-width: #{map-get($breakpoints, xs)})
        //     right: 7%

        &:before {
            content: '';
            display: block;
            width: 5*$base-width;
            height: 5*$base-width;
            position: absolute;
            top: 50%;
            right: 2*$base-width;
            transform: translateY(-50%);
            background: $primary;
            border-radius: 100%;
            z-index: 1; }

        label {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            line-height: 1;
            font-weight: 400; }

        select.form-control {
            min-width: 23*$base-width;
            height: 8*$base-width;
            appearance: none;
            color: $dark;
            font-size: 1.4rem;
            font-weight: 400;
            background: transparent;
            border: 0;
            box-shadow: none;
            cursor: pointer;
            z-index: 2;

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                min-width: 16*$base-width; }

            &:focus + svg {
                transform: translateY(-50%) rotate(180deg); }

            option {
                color: $dark;
                cursor: pointer; } }

        svg {
            position: absolute;
            top: 50%;
            right: 4*$base-width;
            transform: translateY(-50%);
            color: $light;
            z-index: 1;
            transition: transform .3s ease-in-out; } } }
