a {
    color: $primary;
    text-decoration: underline;
    transition: $transition;

    &:hover {
        color: $secondary; }

    &:focus {
        outline: 0; }

    &:not(.btn) {
        @media (max-width: #{map-get($breakpoints, s)}) {
            overflow-wrap: break-word; } } }

strong,
.bold {
    font-weight: 700; }

p + p {
    padding-top: 3 * $base-width;
    font-family: $font-default; }

.text {
    ul,
    ol {
        padding-left: 3 * $base-width;

        li + li {
            margin-bottom: 1.5 * $base-width; } }

    * + ul,
    * + ol {
        margin-top: 2.5*$base-width; }

    // * + h1,
    // * + h2,
    // * + h3,
    // * + h4,
    // * + h5,
    // * + h6
    //     padding-top: 5 * $base-width

    blockquote {
        margin: 5 * $base-width 0 2 * $base-width;
        padding: 4 * $base-width;
        background: $bg-color;
        border-radius: $border-radius;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding: 3 * $base-width;
            border-radius: 20px; }

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 2 * $base-width; }

        p:last-child {
            padding-bottom: 0; } }

    a:not(.btn) {
        @media (max-width: #{map-get($breakpoints, s)}) {
            overflow-wrap: break-word; } } }

.align-center {
    text-align: center; }

button {
    &:focus {
        outline: none; } }

h1,
.h1 {
    font-family: $headline-font;
    font-size: 6rem;
    line-height: 1.1;
    display: block;
    color: $primary;
    font-weight: 500;
    margin-bottom: 3.2rem;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        font-size: 5rem; }
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 4.5rem; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 4rem; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 3.5rem;
        margin-bottom: 2.2rem; } }

h2,
.h2,
.transparenz {
    font-family: $headline-font;
    font-size: 6rem;
    line-height: 1.1;
    display: block;
    font-weight: 500;
    color: $primary;
    margin-bottom: 3.125 * $base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        font-size: 5rem; }
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 4rem; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 3rem;
        margin-bottom: 2.5*$base-width; } }


h3,
.h3 {
    font-family: $headline-font;
    font-size: 4.5rem;
    line-height: 1.2;
    display: block;
    font-weight: 500;
    color: $primary;
    margin-bottom: 4*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        font-size: 4rem; }
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 3rem; } }


h4,
.h4 {
    font-family: $headline-font;
    font-size: 3rem;
    line-height: 1.2;
    display: block;
    font-weight: 500;
    color: $primary;
    margin-bottom: 1.5rem;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 2rem;
        margin-bottom: 1rem; } }




h2.big,
.h2.big {}
.big {
    font-family: $headline-font;
    font-size: 20rem;
    display: block;
    font-weight: 500;
    color: $grey;
    position: absolute;
    top: 0;
    line-height: 1;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        font-size: 18rem;
        top: 15px; }
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 14rem;
        top: 55px; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 10rem;
        top: 30px; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 6rem;
        top: 26px; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 5rem;
        top: 35px; } }

.preline {
    font-family: $font-default;
    font-weight: $bold;
    font-size: 1.6rem;
    text-transform: uppercase;
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        font-size: 1.4rem; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 1.3rem; } }

.subline {
    font-family: $font-default;
    font-weight: $bold;
    margin-bottom: 1.875 * $base-width; }

.btn.btn-primary {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 400;
    border-radius: 30px;
    width: fit-content;
    padding: 17px 30px 18px 30px;
    line-height: 1;
    display: flex;
    align-items: center;
    color: $light;
    border: 2px solid $light;
    font-family: $font-default;
    transition: $transition;
    &:hover {
        transition: $transition;
        color: $primary;
        background-color:  $light; }

    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 1.4rem;
        padding: 15px 20px 16px 20px; }

    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
        font-size: 1.2rem; } }

.btn.btn-secondary {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 400;
    border-radius: 30px;
    width: fit-content;
    padding: 17px 30px 18px 30px;
    line-height: 1;
    display: flex;
    align-items: center;
    color: $dark;
    border: 2px solid $dark;
    font-family: $font-default;
    transition: $transition;
    &:hover {
        transition: $transition;
        color: $light;
        background-color: $primary;
        border: 2px solid $primary; }
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 1.4rem;
        padding: 15px 20px 16px 20px; }
    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
        padding: 15px 18px 16px 18px;
        font-size: 1.2rem; } }


.btn.btn-link {
    text-transform: uppercase;
    font-size: 1.6rem;
    text-decoration: none;
    color: $dark;
    font-family: $font-default;
    transition: $transition;
    svg {
        margin-left: 10px;
        transition: $transition; }
    &:hover {
        transition: $transition;
        color: $primary;
        svg {
            transition: $transition;
            margin-left: 20px;
            fill: $primary; } }
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 1.4rem; } }

