// Playfair - Headline
@font-face {
    font-family: 'Playfair';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/playfair.woff2') format('woff2'),
    url('../../fonts/playfair.woff') format('woff') {}
    font-display: swap; }




// // SofiaPro
// @font-face
//     font-family: 'SofiaPro'
//     font-style: normal
//     font-weight: 400
//     src: url('../../fonts/SofiaPro.woff2') format('woff2'),
//     url('../../fonts/SofiaPro.woff') format('woff')
//     font-display: swap

// // SofiaPro
// @font-face
//     font-family: 'SofiaPro'
//     font-style: normal
//     font-weight: 300
//     src: url('../../fonts/SofiaPro-Light.woff2') format('woff2'),
//     url('../../fonts/SofiaPro-Light.woff') format('woff')
//     font-display: swap

// // SofiaPro
// @font-face
//     font-family: 'SofiaPro'
//     font-style: normal
//     font-weight: 600
//     src: url('../../fonts/SofiaPro-Bold.woff2') format('woff2'),
//     url('../../fonts/SofiaPro-Bold.woff') format('woff')
//     font-display: swap



@font-face {
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    font-style: normal; }

@font-face {
    font-family: "sofia-pro", sans-serif;
    font-weight: 300;
    font-style: normal; }

@font-face {
    font-family: "sofia-pro", sans-serif;
    font-weight: 500;
    font-style: normal; }

@font-face {
    font-family: "sofia-pro", sans-serif;
    font-weight: 700;
    font-style: normal; }
