.block-instagram-wall {
    position: relative;

    .gallery-grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -2 * $base-width;
        padding-bottom: 20*$base-width;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            padding: 15*$base-width 0; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 10*$base-width 0; }

        .gallery-frame {
            flex: 0 0 33.333%;
            padding: 2 * $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex: 0 0 50%; }

            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                flex: 0 0 100%; }

            a {
                position: relative;
                display: block;

                &:hover {
                    .caption {
                        opacity: 1;
                        transition: $transition; } } }


            picture {
                display: block;
                position: relative;
                width: 100%;
                padding-top: 100%;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; } }

            &.logo {
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: #{map-get($breakpoints, l)}) {
                    display: none; }
                img {
                    max-width: 300px;
                    margin: 0 auto; } } } }

    .caption {
        transition: $transition;
        display: flex;
        align-items: center;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        padding: 8*$base-width;
        text-align: center;
        color: $light;
        background: linear-gradient(0deg, rgba(#4D7F93, .82) 0%, rgba(#355460, .82) 100%);
        opacity: 0;

        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            padding: 6*$base-width; }

        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            padding: 4*$base-width; }

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            display: none; }

        &:after {
            content: '';
            position: absolute;
            inset: $base-width;
            border: 1px solid $light; } }



    &.start {
        padding-top: 5*$base-width;
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding-top: 0; }
        .wrapper {
            max-width: $max;
            padding: 0; }
        .hl-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 8*$base-width;
            transition: $transition;
            a.btn {
                position: relative;
                display: flex;
                svg {
                    margin-right: 10px;
                    path {
                        fill: $dark; } }
                &:hover {
                    svg {
                        path {
                            transition: $transition;
                            fill: $light; } } } } }
        .gallery {
            display: flex;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                overflow-x: scroll; }
            .gallery-frame {
                flex: 0 0 16.66%;
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 24%; }

                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    flex: 0 0 32%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 45%; }

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    flex: 0 0 90%; } }


            a {
                position: relative;
                display: block;
                &:hover {
                    .caption {
                        opacity: 1;
                        transition: $transition; } } } }


        picture {
            display: block;
            position: relative;
            width: 100%;
            padding-top: 100%;

            img {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center; } }


        .caption {
            display: flex;
            align-items: center;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            padding: 8*$base-width;
            text-align: center;
            color: $light;
            background: linear-gradient(0deg, rgba(#4D7F93, .82) 0%, rgba(#355460, .82) 100%);
            opacity: 0;
            transition: $transition;

            p {
                line-height: 1.6;
                font-size: 16px; }

            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                padding: 6*$base-width;
                p {
                    line-height: 1.4;
                    font-size: 14px; } }
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                padding: 4*$base-width; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                display: none; }

            &:after {
                content: '';
                position: absolute;
                inset: $base-width;
                border: 1px solid $light; } } } }
