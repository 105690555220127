.block-aktion-angebot {
    padding: 15rem 0;
    position: relative;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        padding: 10rem 0; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 8rem 0; }


    picture {
        // padding-top: 42vh
        position: relative;
        display: block;
        width: 100%;

        // @media only screen and (max-width: #{map-get($breakpoints, xl)})
        //     padding-top: 30vh

        // img
        //     position: absolute
        //     top: 0
        //     left: 0
        //     right: 0
        //     bottom: 0
        //     width: 100%
        //     height: 100%
        //     object-fit: cover
 }        //     object-position: center

    .inner {
        padding-bottom: 8*$base-width;
        max-width: 1010px;
        &.align-center {
            margin: 0 auto;
            text-align: center;
            .btn {
                margin: auto; } }
        p {
            margin-bottom: 3.5rem; }
        .btn {
            svg {
                margin-right: 10px;
                path {
                    fill: $dark;
                    stroke: $dark;
                    transition: $transition; } }
            &:hover {
                svg {
                    path {
                        transition: $transition;
                        fill: $light;
                        stroke: $light; } } } } } }

.block-aktion-angebot + .block-aktion-angebot {
    padding-top: 0; }
