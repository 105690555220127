* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: scroll; }

body {
    -webkit-text-size-adjust: none;
    max-width: $max;
    font-size: 1.9rem;
    color: $dark;
    line-height: 160%;
    font-weight: 300;
    font-family: $font-default;
    overflow-x: hidden;
    margin: 0 auto;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        font-size: 1.8rem; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        font-size: 1.6rem; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 1.5rem; } }

img {
    display: block;
    max-width: 100%; }

main,
.block {
    position: relative; }

.wrapper {
    max-width: $boxed;
    margin: 0 auto;
    padding: 0 2.5*$base-width;

    &-max {
        max-width: $max; }

    &-small {
        max-width: $small; } }


// ul
//     list-style-type: none
//     padding: 20px 0

//     li
//         position: relative
//         padding-left: 32px
//         line-height: 1
//         padding-bottom: 2.5*$base-width
//         &:last-child
//             padding-bottom: 0
//         &::before
//             content: ''
//             position: absolute
//             left: 0
//             top: 0
//             bottom: 0
//             background-image: url(/wp-content/themes/bergauf/blocks/projects/icons/checkpoint.svg)
//             max-width: 20px
//             width: 100%
//             height: 20px
//             background-size: 20px
