@import url("https://use.typekit.net/zqw7tca.css");

/* Third-Party */
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';
@import '~owl.carousel/dist/assets/owl.carousel';


/* Globals */
@import './globals/vars';
@import './globals/fonts';
@import './globals/base';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/footer';
@import './layout/chat';
@import './layout/404';
@import './components/isotope-sort';


/* Blocks */
@import '../../blocks/text-media/style';
@import '../../blocks/hero/style';
@import '../../blocks/services/style';
@import '../../blocks/hotspot-teaser/style';
@import '../../blocks/text-columns/style';
@import '../../blocks/associate/style';
@import '../../blocks/slider-simple/style';
@import '../../blocks/shop-the-look/style';
@import '../../blocks/diy-tipps/style';
@import '../../blocks/wohntrend-klickpoints/style';
@import '../../blocks/employee-sort/style';
@import '../../blocks/mediathek/style';
@import '../../blocks/catalogs/style';
@import '../../blocks/our-house/style';
@import '../../blocks/form/style';
@import '../../blocks/projects/style';
@import '../../blocks/banner/style';
@import '../../blocks/aktion-angebot/style';
@import '../../blocks/instagram-wall/style';
@import '../../blocks/appointment-booking/style';
@import '../../blocks/kuechenslider/style';
@import '../../blocks/google-feedback/style';
@import '../../blocks/newsletter/style';
@import '../../blocks/berbel/style';
