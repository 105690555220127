.block-text-columns {
    &.p-bt {
        padding-top: 250px;
        padding-bottom: 200px;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            padding-top: 150px;
            padding-bottom: 100px; } }
    .wrapper {
        max-width: 750px;
        text-align: center;
        opacity: 0; }


    .text-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        .col {

            &.col-1 {
                flex: 0 0 100%; }

            &.col-2 {
                flex: 0 0 50%;

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 100%;
                    padding-top: 2.5*$base-width; } }
            &.col-3 {
                flex: 0 0 33.333%;

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; } }

            &.col-4 {
                flex: 0 0 25%;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 33.333%; }

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; } }

            ul {
                padding-left: 3 * $base-width;

                li {
                    margin-bottom: $base-width; } }


            .btn {
                margin-top: 2 * $base-width; } } }

    &.full {
        .wrapper {
            max-width: $small;
            text-align: left; } }

    &.animation-element {
        .wrapper {
            animation: sparkle-in 1s ease forwards;
            animation-delay: 0.5s; }

        @keyframes sparkle-in {
            0% {
                opacity: 0; }
            50% {
                opacity: 0.5; }
            100% {
                opacity: 1; } } }

    &.history {
        padding-bottom: 20 * $base-width;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            padding-bottom: 11rem; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            padding-bottom: 10*$base-width; }
        .wrapper {
            max-width: $boxed;
            opacity: 0;
            text-align: left;
            .headline-wrapper {
                max-width: 750px;
                text-align: center;
                margin: 0 auto;
                margin-bottom: 10*$base-width;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    margin-bottom: 7.5*$base-width; }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    margin-bottom: 5*$base-width; } }


            .text-wrapper {
                justify-content: space-around;
                .col {
                    opacity: 0;
                    &.col-2 {
                        flex: 0 0 33%;

                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            flex: 0 0 100%;
                            padding-top: 2.5*$base-width; } }

                    &:last-child {
                        padding-top: 6.25*$base-width;
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            padding-top: 2.5*$base-width; } } } } }

        &.in-view {
            .text-wrapper {
                .col {
                    &:first-child {
                        animation: slide-in-first 1.5s ease forwards; }

                    @keyframes slide-in-first {
                        from {
                            margin-left: -25%;
                            margin-right: 25%;
                            opacity: 0; }

                        to {
                            margin-left: 0;
                            margin-right: 0;
                            opacity: 1; } }

                    &:last-child {
                        animation: slide-in-last 1.5s ease forwards; }

                    @keyframes slide-in-last {
                        from {
                            margin-right: -25%;
                            margin-left: 25%;
                            opacity: 0; }

                        to {
                            margin-right: 0;
                            margin-left: 0;
                            opacity: 1; } } } } } } }





.p-bottom {
    padding-bottom: 10*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        padding-bottom: 5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-bottom: 2.5*$base-width; } }

.no-hero {
    padding-top: 21.5rem;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        padding-top: 16.5rem; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 13.5rem; } }
