footer {
    .newsletter {
        background: linear-gradient(256deg, #4D7F93 0%, #355460 100%) 0% 0% no-repeat padding-box;
        color: $light;
        text-align: center;
        position: relative;
        .nl-svg-left {
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0;
            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                left: -2%;
                svg {
                    max-height: 200px; } }
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                left: -5%; }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                left: -10%;
                svg {
                    max-height: 150px; } }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                left: -14%; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                left: -25%;
                svg {
                    max-height: 115px; } } }
        .nl-svg-right {
            position: absolute;
            bottom: 0;
            right: -2%;
            opacity: 0;
            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                svg {
                    max-height: 220px; } }
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                right: -8%; }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                right: -12%;
                svg {
                    max-height: 180px; } }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                right: -18%; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                right: -35%;
                svg {
                    max-height: 110px; } } }
        .wrapper {
            max-width: $small;
            margin: 0 auto;
            padding: 8*$base-width 2.5*$base-width 15*$base-width;
            h3 {
                color: $light;
                svg {
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        display: none; } } }
            p {
                font-size: 1.2rem;
                line-height: 1.4;
                letter-spacing: 0.2px;
                max-width: 790px;
                margin: 0 auto; }
            .layout_form {
                padding-bottom: 4*$base-width;
                .editable_content {
                    display: flex;
                    justify-content: space-between;
                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        flex-direction: column; }
                    .cr_form-inputgroup {
                        position: relative;
                        .cr_form-input {
                            height: 60px;
                            border-radius: 0;
                            padding: 10px 20px;
                            font-size: 1.6rem;
                            border: unset;
                            width: 100%;
                            &:focus, &.filled {
                                outline: 0;
                                + label {
                                    transition:  $transition;
                                    top: -12px;
                                    font-size: 1.4rem;
                                    color: $light;
 } } }                                    // background-color: $light
                        label {
                            position: absolute;
                            pointer-events: none;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 20px;
                            transition: all 0.3s ease-in-out;
                            display: inline-block;
                            padding-bottom: 0;
                            color: $primary;
                            text-transform: uppercase;
                            font-size: 1.6rem; } }
                    .cr_form-component--text {
                        flex: 0 0 20%;
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            flex: 0 0 100%;
                            margin: 15px 0; } }
                    .cr_form-component--email {
                        flex: 0 0 36.454%;
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            flex: 0 0 100%;
                            margin: 15px 0; } }
                    .btn.btn-primary {
                        background: unset;
                        &:hover {
                            background: $light; } }
                    .cr_form-component--submit {
                        flex: 0 0 19%;
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            flex: 0 0 100%;
                            .btn.btn-primary {
                                margin: 0 auto;
                                margin-top: 10px; } } } } }


            a {
                color: #C4C4C4;
                text-decoration: none; } }

        &.in-view {
            .nl-svg-left {
                animation-delay: 200ms;
                animation: slide-in-svg-al 1.5s ease forwards; }

            @keyframes slide-in-svg-al {
                from {
                    margin-left: -25%;
                    margin-right: 25%;
                    opacity: 0; }

                to {
                    margin-left: 0;
                    margin-right: 0;
                    opacity: 1; } }

            .nl-svg-right {
                animation-delay: 250ms;
                animation: slide-in-svg 1.5s ease forwards; }

            @keyframes slide-in-svg {
                from {
                    margin-right: -25%;
                    margin-left: 25%;
                    opacity: 0; }

                to {
                    margin-right: 0;
                    margin-left: 0;
                    opacity: 0.1; } } } }

    .teaser-row {
        display: flex;
        flex-wrap: wrap;
        .teaser {
            flex: 1 1 33.33%;
            max-height: 100%;
            position: relative;
            flex-direction: column;
            justify-content: center;
            display: flex;
            position: relative;
            background-color: #E8E8E8;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                flex: 1 1 50%; }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 100%; }
            .inner-teaser {
                max-width: 640px;
                margin: 0 auto;
                flex-direction: column;
                display: flex;
                padding: 80px;
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    padding: 5*$base-width; }
                a.btn {
                    text-decoration: none;
                    color: $dark;
                    text-transform: uppercase;
                    font-size: 1.6rem;
                    font-weight: 400;
                    border: 2px solid $dark;
                    border-radius: 30px;
                    width: fit-content;
                    padding: 17px 30px 18px 30px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    svg {
                        position: relative;
                        margin-right: 10px; }
                    &:hover {
                        transition: $transition;
                        color: $light;
                        background-color: $primary;
                        border: 2px solid $primary;
                        svg {
                            path {
                                stroke: $light;
                                fill: $light; } } }

                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        font-size: 1.4rem;
                        padding: 15px 20px 16px 20px; } } }

            p {
                margin-bottom: 35px;
                line-height: 1.8; }
            svg {
                position: absolute;
                bottom: 0;
                right: 0;
                pointer-events: none;
                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                    max-width: 200px;
                    max-height: 150px; }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    max-width: 200px;
                    max-height: 150px; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    max-width: 150px;
                    max-height: 100px; }
                @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                    max-width: 110px;
                    max-height: 100px; } }



            &.imgteaser {
                .bg-wrapper {
                    background: linear-gradient(90deg, rgba(53,84,96,1) 0%, rgba(77,127,147,1) 50%, rgba(53,84,96,1) 100%);
                    .inner-teaser {
                        color: $light;
                        h3 {
                            color: $light; }
                        a.btn {
                            color: $light;
                            border: 2px solid $light;
                            &:hover {
                                transition: $transition;
                                color: $primary;
                                background-color:  $light; } } } }

                .teaserimg {
                    max-height: 217px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transform: scaleX(-1); } } }

            &.onlytext {
                &:first-child {
                    svg {
                        position: absolute;
                        bottom: 4%;
                        right: -32%;
                        transform: rotate(-15deg);
                        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                            max-width: 500px;
                            max-height: 200px;
                            transform: rotate(-20deg); }
                        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                            right: -10%; }
                        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                            max-width: 600px;
                            max-height: 200px;
                            right: -25%; }
                        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                            max-width: 310px;
                            max-height: 115px;
                            bottom: 0; }
                        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                            max-width: 310px;
                            max-height: 115px;
                            bottom: 0;
                            right: -30%;
                            transform: rotate(-22deg); } }

                    a.btn {
                        svg {
                            bottom: unset;
                            right: unset;
                            position: relative;
                            transform: unset; } } } } } }

    .footerrow {
        display: flex;
        max-width: $boxed;
        margin: 0 auto;
        padding: 17*$base-width 2.5*$base-width 10*$base-width;
        flex-wrap: wrap;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            padding: 10*$base-width 2.5*$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 5*$base-width 2.5*$base-width; }
        .cols {
            width: 100%;
            font-size: 1.7rem;
            &:nth-child(1) {
                flex: 1 1 25%;
                padding-right: 40px; }
            &:nth-child(2) {
                flex: 1 1 25%;
                padding-right: 40px; }
            &:nth-child(3) {
                flex: 1 1 33.33%; }

            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                &:nth-child(1) {
                    flex: 1 1 50%;
                    padding-right: 40px; }
                &:nth-child(2) {
                    flex: 1 1 50%;
                    padding-right: 40px; }
                &:nth-child(3) {
                    flex: 1 1 100%;
                    padding-top: 10*$base-width; } }

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                &:nth-child(1) {
                    flex: 0 0 100%;
                    padding-right: 0; }
                &:nth-child(2) {
                    flex: 0 0 100%;
                    padding-right: 0;
                    padding-top: 10*$base-width; } }

            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                &:nth-child(2) {
                    padding-top: 5*$base-width; }
                &:nth-child(3) {
                    padding-top: 5*$base-width; } }

            .semibold {
                font-weight: $bold; }
            p + p {
                padding-top: 0; }

            a {
                color: $dark;
                text-decoration: none; }

            &.contact {
                .adress-block {
                    padding-bottom: 25px; } }

            .footer-item {
                a {
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: 8px; } } }

            .mate {
                display: flex;
                align-items: center;
                padding-top: 5*$base-width;
                .partner {
                    max-width: 63px;
                    max-height: 63px;
                    margin-right: 17px; }
                p {
                    line-height: 1.5;
                    max-width: 245px; } }

            &.opening {
                .btn.contact {
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    transition: $transition;
                    svg {
                        margin-left: 10px;
                        margin-right: 0;
                        transition: $transition; }
                    &:hover {
                        transition: $transition;
                        color: $primary;
                        svg {
                            transition: $transition;
                            margin-left: 20px;
                            path {
                                transition: $transition;
                                fill: $primary; } } } }

                .office_hours {
                    padding-bottom: 5*$base-width; }

                .socials {
                    display: flex;
                    padding-top: 5*$base-width;
                    .footer-item {
                        a {
                            background-color: $primary;
                            border-radius: 50%;
                            width: 38px;
                            height: 38px;
                            justify-content: center;
                            transition: $transition;
                            svg {
                                margin-right: 0; }
                            &:hover {
                                background-color: $secondary;
                                transition: $transition; } }
                        &:last-child {
                            margin-left: 10px; } } } } }

        &.two {
            position: relative;
            padding: 10*$base-width 2.5*$base-width 17*$base-width;
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding: 10*$base-width 2.5*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding: 5*$base-width 2.5*$base-width; }

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0%;
                left: 50%;
                transform: translateX(-50%);
                background-image: linear-gradient(to right, #C4C4C4 0%, #C4C4C4 50%, transparent 50%);
                background-size: 10px 2px;
                background-repeat: repeat-x;
                z-index: -1; }
            .haus {
                padding-bottom: 5*$base-width;
                &:last-child {
                    padding-bottom: 0; } }

            .cols {
                p {
                    strong {
                        padding-bottom: 10px; } } }

            .shop {
                .footer-item.shop {
                    display: flex;
                    flex-direction: column; }

                .shop_logo {
                    height: 125px;
                    max-width: 316px;
                    margin-bottom: 25px;
                    svg {
                        width: 100%;
                        height: 100%; }
                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        height: 105px;
                        max-width: 255px;
                        margin-bottom: 15px; } }

                .btn.btn-secondary {
                    svg {
                        path {
                            transition: $transition; } }
                    &:hover {
                        svg {
                            path {
                                transition: $transition;
                                fill: $light;
                                stroke: $light; } } } } } } }


    .menü {
        background-color: #E8E8E8;
        .inner {
            max-width: $boxed;
            padding: 10px 2.5*$base-width;
            margin: 0 auto;
            display: flex;
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex-wrap: wrap;
                padding: 5*$base-width 2.5*$base-width;
                justify-content: center; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                flex-direction: column;
                justify-content: flex-start; }

            .footer-menu {
                display: flex;
                list-style-type: none;
                padding-right: 12.5*$base-width;
                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    padding-right: unset;
                    padding-bottom: 10px; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    flex-direction: column; }
                li {
                    padding: 0 19px;
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        padding: 0 0 15px; }
                    a {
                        color: $dark;
                        transition: $transition;
                        text-decoration: none;
                        font-size: 1.7rem;
                        &:hover {
                            transition: $transition;
                            color: $secondary; } }

                    &:first-child {
                        padding-left: 0; } } }

            p {
                font-size: 1.7rem; } } } }
