.consultation {
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 99;
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        display: none; }

    .chat {
        a {
            display: inline-block;
            line-height: 1;
            background-color: $primary;
            display: flex;
            align-items: center;
            border-top-left-radius: 30px;
            width: 100%;
            height: 100%;
            color: $light;
            text-decoration: none;
            padding: 17px 30px 18px 30px;
            font-size: 1.6rem;
            text-transform: uppercase;
            svg {
                margin-right: 10px; }


            &:hover {
                transition: $transition;
                background-color: $hover; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding: 17px 20px 18px 25px;
                .text {
                    display: none; }
                svg {
                    margin-right: 0;
                    width: 30px;
                    height: 30px; } } } } }
