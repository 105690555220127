.block-wohntrend-klickpoints {
    padding-top: 6.25 * $base-width;
    padding-bottom: 20*$base-width;
    max-width: $wrapped;
    margin: 0 auto;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding-bottom: 17.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        padding: 6.25*$base-width 2.5*$base-width; }

    .wohntrend-klickpoint-slider {
        position: relative;
        .owl-stage-outer {
            overflow: hidden;
            position: relative;
            .owl-stage {
                // display: flex
                // align-items: center
                // justify-content: center
                .owl-item {
                    position: relative;
                    &.active {
                        &::before {
                            content: none; }
                        &::after {
                            content: '';
                            max-width: 671px;
                            width: 100%;
                            max-height: 637px;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 20px;
                            background-color: $grey;
                            z-index: -1;
                            @media only screen and (max-width: 1850px) {
                                left: 80px; }
                            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                                left: 40px; }
                            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                left: 20px; }
                            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                content: unset; } } }
                    &.second {
                        &::before {
                            transform: scaleX(-1);
                            content: '';
                            position: absolute;
                            width: 403px;
                            height: 100%;
                            z-index: 5;
                            left: 0;
                            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8015581232492998) 35%, rgba(255,255,255,0) 100%); }
                        .anchorpoint_svg {
                            display: none; }
                        .anchorpoint_modal {
                            display: none; } }
                    &.left {
                        &::before {
                            content: '';
                            position: absolute;
                            width: 400px;
                            height: 100%;
                            z-index: 5;
                            right: 0;
                            left: unset;
                            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8015581232492998) 35%, rgba(255,255,255,0) 100%); }
                        .anchorpoint_svg {
                            display: none; }
                        .anchorpoint_modal {
                            display: none; } } } } }


        .owl-nav {
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            width: 100%;
            display: flex;
            justify-content: space-between;
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                display: none; }
            button {
                border-radius: 50%;
                background-color: $light;
                width: 65px;
                height: 65px;
                border: 1px solid $dark;
                &.owl-prev {
                    position: absolute;
                    left: 5%; }
                &.owl-next {
                    position: absolute;
                    right: 5%; }
                &:hover {
                    transition: $transition;
                    background-color: $primary;
                    border: 1px solid $primary;
                    svg {
                        path {
                            transition: $transition;
                            fill: $light; } } } } }


        .owl-dots {
            width: 100%;
            display: flex;
            margin-top: 30px;
            justify-content: center;
            align-items: center;
            .owl-dot {
                border-radius: 50%;
                border: 1px solid $primary;
                background-color: $light;
                width: 10px;
                height: 10px;
                margin: 0 5px; }
            .active {
                border: 1px solid $primary;
                background-color: $primary; } } }


    .klickpointimg {
        max-width: 1024px;
        position: relative;
        margin: 0 auto;
        width: 100%;
        overflow:  hidden;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            max-width: 750px; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            max-width: 700px; }
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            max-width: 100%; }
        picture {
            display: block;
            height: 768px;
            width: 100%;
            margin-top: 20px;
            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                height: 550px; }
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                height: 500px; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                height: 450px; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                height: 350px; }
            @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                height: 250px; }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                object-position: top; } }
        .anchorpoint_svg {
            z-index: 10;
            cursor: pointer;
            &.show_active {
                z-index: 20 !important; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                display: none; }
            &:hover {
                svg {
                    #outline {
                        transition: $transition;
                        fill: #355460; }
                    #center {
                        transition: $transition;
                        fill: #fff; } } } }

        .anchorpoint_modal {
            display: none;
            background-color: $light;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
            border-radius: 30px;
            text-decoration: none;
            padding: 30px 44px;
            z-index: 8;
            transition: $transition;
            &.show_active {
                z-index: 15 !important; }
            .inner {
                max-width: 240px; }
            h4 {
                color: $dark; }
            &:hover {
                h4 {
                    color: $dark; } }
            &.show_an {
                display: block;
                transition: $transition; }

            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                display: none; } } } }
