.block-kuechenslider {
  position: relative;
  margin: 0 auto;
  padding-bottom: 20*$base-width;
  @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
    padding-bottom: 17.5*$base-width; }
  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    padding-bottom: 10*$base-width; }
  .start {
    padding-bottom: 5.5*$base-width;
    .btn.btn-secondary {
      margin: 0 auto;
      svg {
        margin-right: 10px; }
      &:hover {
        transition: $transition;
        svg {
          path {
            transition: $transition;
            stroke: $light;
            fill: $light; } } } } }

  .kitchen-slider-inner {
    .owl-stage-outer {
      margin: auto;
      z-index: 1;
      .kitchen-slider-item {
        justify-content: center;
        height: 496px;
        max-width: 496px;
        width: 100%;
        margin: 0 8px;
        &:first-child {
          margin-left: 0; }
        &:last-child {
          margin-right: 0; }
        .img-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          .kitchen-picture {
            width: 100%;
            display: block;
            height: 100%;
            img {
              display: block;
              height: 100%;
              width: 100%;
              object-fit: cover; } }

          a {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            &.afterbg {
              &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 85%, black 100%); } }

            &:hover {
              transition: $transition;
              .overlay {
                transition: $transition;
                opacity: 1;
                svg {
                  opacity: 1;
                  transition: $transition; } }
              &.afterbg {
                &::after {
                  content: unset;
                  transition: $transition; } }
              .caption {
                opacity: 0; } } }


          .overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 456px;
            max-width: 456px;
            width: 100%;
            height: 100%;
            background-color: rgba(77, 127, 147, 0.88);
            opacity: 0;
            transition: $transition;
            z-index: 9;
            align-items: center;
            justify-content: center;
            display: flex;

            svg {
              max-width: 5.9rem;
              width: 100%;
              max-height: 5.9rem;
              height: 100%;
              fill: $light;
              opacity: 1; } } }

        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
          height: 400px; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
          max-width: 100%; } } }

    .owl-dots {
      width: 100%;
      display: flex;
      margin-top: 30px;
      justify-content: center;
      align-items: center;
      .owl-dot {
        border-radius: 50%;
        border: 1px solid $primary;
        background-color: $light;
        width: 10px;
        height: 10px;
        margin: 0 5px; }
      .active {
        border: 1px solid $primary;
        background-color: $primary; } }

    .owl-nav {
      position: absolute;
      top: 43%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      justify-content: space-between;
      button {
        border-radius: 50%;
        background-color: $light;
        width: 65px;
        height: 65px;
        border: 1px solid $dark;
        &.owl-prev {
          position: absolute;
          left: -8%; }
        &.owl-next {
          position: absolute;
          right: -8%; }
        svg {
          height: 100%; }
        &:hover {
          transition: $transition;
          background-color: $primary;
          border: 1px solid $primary;
          svg {
            path {
              transition: $transition;
              fill: $light; } } }
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          display: none; } } } }

  .kitchen-slider-inner-no {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .kitchen-slider-item {
      height: 496px;
      max-width: 496px;
      width: 100%;
      margin: 20px 8px 0;
      &:first-child {
        margin-left: 0; }
      &:last-child {
        margin-right: 0; }

      .kitchen-slider-picture {
        width: 100%;
        height: 100%;
        margin-left: 30px;
        &:first-child {
          margin-left: 0; }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; }

        a {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          &.afterbg {
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 100%;
              z-index: 9;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 85%, black 100%); } }

          &:hover {
            .overlay {
              transition: $transition;
              opacity: 1;
              svg {
                opacity: 1;
                transition: $transition; } }
            &.afterbg {
              &::after {
                content: unset;
                transition: $transition; } }
            .caption {
              opacity: 0; } } }


        .overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-height: 456px;
          max-width: 456px;
          width: 100%;
          height: 100%;
          background-color: rgba(77, 127, 147, 0.88);
          opacity: 0;
          transition: $transition;
          z-index: 9;
          align-items: center;
          justify-content: center;
          display: flex;

          svg {
            max-width: 5.9rem;
            width: 100%;
            max-height: 5.9rem;
            height: 100%;
            fill: $light;
            opacity: 1; } } }

      @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        height: 450px;
        max-width: 450px;
        .kitchen-slider-picture {
          .overlay {
            max-height: 400px;
            max-width: 400px; } } }

      @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        height: 400px;
        max-width: 400px;
        .kitchen-slider-picture {
          .overlay {
            max-height: 350px;
            max-width: 350px; } } }

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        height: 280px;
        max-width: 280px;
        .kitchen-slider-picture {
          .overlay {
            max-height: 250px;
            max-width: 250px; } } }

      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        .kitchen-slider-picture {
          .overlay {
            display: none; } } }

      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        width: 100%;
        max-width: 100%;
        height: 300px;
        overflow: hidden;
        margin: 20px 0 0;
        .kitchen-slider-picture {
          .overlay {
            display: none; } } } } }

  .caption {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 20px;
    font-family: $headline-font;
    font-size: 2rem;
    color: $light;
    transition: $transition;
    z-index: 10;
    @media (max-width: #{map-get($breakpoints, xl)}) {
      font-size: 1.8rem; } } }
