.block-our-house {
    max-width: $boxed;
    margin: 0 auto;
    padding: 0 2.5*$base-width;
    padding-bottom: 20*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding-bottom: 17.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-bottom: 15*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-bottom: 10*$base-width; }


    .house-grid-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 80px;
        .house-container {
            margin: 0;
            width: 100%;
            flex: 0 0 30%;
            position: relative !important;
            top: unset !important;
            left: unset !important;
            opacity: 0;
            @media (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 48%; }
            @media (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%; }


            .house-picture-container {
                position: relative;
                width: 100%;
                height: 100%;
                .house-picture {
                    overflow: hidden;
                    z-index: -1;
                    position: relative;
                    padding-bottom: 100%;
                    display: block;
                    height: 0;
                    img {
                        position: absolute;
                        max-width: 100%;
                        -o-object-fit: cover;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        z-index: -2;
                        top: 0;
                        left: 0; } } }


            .house-info-container {
                background-color: #fff;
                padding: 25px 20px;
                p, a {
                    margin: 0;
                    padding: 0;
                    color: $dark;
                    font-size: 1.9rem;
                    line-height: 37px;
                    @media (max-width: #{map-get($breakpoints, xxxl)}) {
                        font-size: 1.8rem;
                        line-height: 36px; }
                    @media (max-width: #{map-get($breakpoints, m)}) {
                        font-size: 1.7rem;
                        line-height: 34px; } }
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline; } }

                .house-person--item-container {
                    margin-bottom: 25px;
                    h2 {
                        margin-bottom: 25px;
                        display: flex;
                        align-items: center;
                        color: $dark; } }

                .house-info--item-container {
                    a {
                        display: flex;
                        align-items: center; }

                    svg {
                        width: 25px;
                        height: 25px;
                        margin-right: 15px;
                        path {
                            fill: $dark; } } } } }

        .office_hours {
            display: flex;
            margin-top: 25px;
            svg {
                margin-right: 15px;
                margin-top: 6px; }
            .inner {
                display: flex; } } }

    &.in-view {
        .house-container:nth-child(1) {
            animation: slide-in 200ms ease-in-out forwards;
            animation-delay: .35s; }
        .house-container:nth-child(2) {
            animation: slide-in 200ms ease-in-out forwards;
            animation-delay: .45s; }

        .house-container:nth-child(3) {
            animation: slide-in 200ms ease-in-out forwards;
            animation-delay: .55s; }


        @keyframes slide-in {
            0% {
                margin-left: -50%;
                margin-right: 50%;
                opacity: 0; }

            50% {
                margin-left: -25%;
                margin-right: 25%;
                opacity: 0.5; }

            100% {
                margin-left: 0;
                margin-right: 0;
                opacity: 1; } } } }

