.block-text-media {
    padding: 22.5 * $base-width 0;
    max-width: $wrapped;
    margin: 0 auto;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding: 22.5 * $base-width 0 20*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding: 15*$base-width 0; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 10*$base-width 0; }

    .big {
        &.left {
            width: 55%; }
        &.right {
            text-align: right;
            z-index: 5;
            top: -5px;
            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                font-size: 18rem;
                top: 15px; }
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                font-size: 14rem;
                top: 52px; }
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                font-size: 10rem;
                top: 28px; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                font-size: 6rem;
                top: 26px; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                font-size: 5rem;
                top: 34px;
                text-align: center; } } }

    .wrapper {
        display: flex;
        align-content: stretch;
        align-items: stretch;
        flex-wrap: wrap;
        max-width: $full;
        margin: unset;

        &.img-right {
            flex-direction: row-reverse;
            margin-left: auto;
            padding-right: 0; }

        &.img-left {
            flex-direction: row;
            margin-right: auto;
            padding-left: 0; }

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-direction: row-reverse;
            padding: 0; } }

    .text {
        align-content: center;
        flex: 0 0 50%;
        width: 50%;
        padding: 12.5 * $base-width 15*$base-width;
        display: flex;
        justify-content: center;
        flex-direction: column;
        opacity: 0;


        &.img-right {
            padding-left: 0; }

        &.img-left {
            padding-right: 0; }

        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            padding: 7.5*$base-width 10*$base-width; }

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            min-height: unset;
            margin-top: 8 * $base-width;
            padding: 0 2.5*$base-width;
            flex: 0 0 100%;
            width: 100%;

            &.img-right {
                padding-left: 2.5*$base-width; }
            &.img-left {
                padding-right: 2.5*$base-width; } }

        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            margin-top: 6.25*$base-width; }

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            margin-top: 3.75*$base-width; } }

    .media {
        z-index: 2;
        min-height: 100%;
        flex: 0 0 50%;
        width: 50%;
        padding-top: calc(56.25% / 2);
        position: relative;
        opacity: 0;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding-top: calc(100% / 2);
            flex: 0 0 100%;
            width: 100%; }

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center; } }

    .img-left {
        .media {
            &::after {
                content: '';
                max-width: 671px;
                width: 100%;
                max-height: 637px;
                height: 100%;
                position: absolute;
                bottom: -20px;
                right: -20px;
                background-color: $grey;
                z-index: -1;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    content: unset; } } } }


    .img-right {
        .media {
            &::after {
                content: '';
                max-width: 671px;
                width: 100%;
                max-height: 637px;
                height: 100%;
                position: absolute;
                bottom: -20px;
                left: -20px;
                background-color: $grey;
                z-index: -1;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    content: unset; } } } }

    .btn-row {
        display: flex;
        margin-top: 35px;
        .btn {
            margin-right: 24px;
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                margin-right: 18px; } } }



    &.background {
        .bgwrapper {
            background-color: $grey;

            .wrapper {
                padding-right: 0;
                margin: unset;
                margin-left: auto;
                max-width: $full;
                &.img-left {
                    margin-right: auto;
                    margin-left: unset; } } }

        .text {
            align-content: center;
            flex: 0 0 44%;
            width: 44%;
            padding: 25*$base-width  8.75 * $base-width;

            &.img-right {
                padding-left: 0; }

            &.img-left {
                padding-right: 0;
 }                // padding-left:  8.75 * $base-width

            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                padding: 18.76*$base-width 8.75 * $base-width; }

            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                padding: 12.5*$base-width 8.75 * $base-width; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 100%;
                width: 100%;
                padding: 0 2.5*$base-width 8.75*$base-width 2.5*$base-width;
                &.img-right {
                    padding-left: 2.5*$base-width; }

                &.img-left {
                    padding-right: 2.5*$base-width; } }

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                padding-bottom: 6.25*$base-width; }

            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding-bottom: 7.5*$base-width;
                margin-top: 6.25*$base-width; } }
        .media {
            z-index: 2;
            min-height: 100%;
            flex: 0 0 56%;
            width: 56%;
            padding-top: calc(56.25% / 2);
            position: relative;
            &::after {
                content: unset; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 100%;
                width: 100%;
                padding-top: calc(100% / 2); } } }


    &.list {
        .big {
            padding-left: 20px;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding-left: 0; } }
        .bgwrapper {
            .wrapper {
                padding-right: 0;
                margin: unset;
                margin-left: auto;
                max-width: $full; } }

        .text {
            align-content: center;
            flex: 0 0 38%;
            width: 38%;
            padding: 0 12.5 * $base-width;
            padding-left: 0;

            .listpoints {
                display: flex;
                align-items: center;
                padding-bottom: 2.5*$base-width;
                &:last-child {
                    padding-bottom: 0; }
                svg {
                    max-width: 20px;
                    width: 100%;
                    height: 21px;
                    margin-right: 10px;
                    margin-top: 1px; } }

            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                flex: 0 0 50%;
                width: 50%; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 100%;
                width: 100%;
                padding: 0 2.5*$base-width; } }

        .media {
            z-index: 2;
            min-height: 100%;
            flex: 0 0 62%;
            width: 62%;
            padding-top: calc(56.25% / 2);
            position: relative;
            &::after {
                content: '';
                max-width: 671px;
                width: 100%;
                max-height: 637px;
                height: 100%;
                position: absolute;
                bottom: -20px;
                left: -20px;
                background-color: $grey;
                z-index: -1;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    content: unset; } }

            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                flex: 0 0 50%;
                width: 50%; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 100%;
                width: 100%;
                padding-top: calc(100% / 2); } } }


    &.in-view {
        .img-left {
            .text {
                animation: slide-in-content-al 1.5s ease forwards; }

            .media {
                animation: slide-in-gallery-al 1.5s ease forwards; }

            @keyframes slide-in-content-al {
                from {
                    margin-right: -25%;
                    margin-left: 25%;
                    opacity: 0; }

                to {
                    margin-right: 0;
                    margin-left: 0;
                    opacity: 1; } }

            @keyframes slide-in-gallery-al {
                from {
                    margin-left: -25%;
                    margin-right: 25%;
                    opacity: 0; }

                to {
                    margin-left: 0;
                    margin-right: 0;
                    opacity: 1; } } } }

    // Animation
    &.in-view {
        .text {
            animation: slide-in-content 1.5s ease forwards; }

        .media {
            animation: slide-in-gallery 1.5s ease forwards; }

        @keyframes slide-in-content {
            from {
                margin-left: -25%;
                margin-right: 25%;
                opacity: 0; }

            to {
                margin-left: 0;
                margin-right: 0;
                opacity: 1; } }

        @keyframes slide-in-gallery {
            from {
                margin-right: -25%;
                margin-left: 25%;
                opacity: 0; }

            to {
                margin-right: 0;
                margin-left: 0;
                opacity: 1; } } } }


.block-text-media + .block-text-media {
    padding-top: 0; }

.block-text-media + .block-text-media.bighl {
    padding-top: 22.5 * $base-width;
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-top: 15*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 10*$base-width; } }


#block_db13031802e907b51290e446944ce8c2 {
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 40px;
        .media {
            display: none; }
        .big {
            display: none; } } }

#block_dbecb3a99c52ad0b40c7cfd9964a600b {
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 0; } }

#block_149cd23eb05f2ea2c3dc6d3348b5582e {
    .big.right {
        top: -3px;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            font-size: 18rem;
            top: 15px; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            font-size: 14rem;
            top: 52px; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            font-size: 10rem;
            top: 28px; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            font-size: 6rem;
            top: 26px; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            font-size: 5rem;
            top: 34px;
            text-align: center; } } }
