.block-mediathek {
    padding-bottom: 20*$base-width;
    // max-width: $wrapped
    // margin: 0 auto
    padding-top: 20 * $base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding-bottom: 20*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding: 15*$base-width 0; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        position: unset; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 10*$base-width 0; }

    .video-row {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        .video-col {
            flex: 0 0 49%;
            max-width: 100%;
            overflow: hidden;
            position: relative;
            padding: 2.5*$base-width 0;
            opacity: 0;
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex: 0 0 100%; }
            h4 {
                text-align: center; }
            .video-wrap {
                overflow: hidden;
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                .play-button {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                    border: none;
                    background: transparent;
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        svg {
                            max-width: 80px;
                            max-height: 80px; } } }
                img {
                    position: absolute;
                    max-width: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    z-index: 0;
                    top: 0;
                    left: 0; } } } }

    &.in-view {
        .video-col:nth-child(1) {
            animation: 250ms slide-in-left ease forwards;
            animation-delay: .75s; }

        .video-col:nth-child(2) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: .95s; }

        .video-col:nth-child(3) {
            animation: 250ms slide-in-left ease forwards;
            animation-delay: 1.15s; }

        .video-col:nth-child(4) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 1.35s; }

        .video-col:nth-child(5) {
            animation: 250ms slide-in-left ease forwards;
            animation-delay: 1.55s; }

        .video-col:nth-child(6) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 1.75s; }

        .video-col:nth-child(7) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 1.95s; }

        .video-col:nth-child(8) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 2.15s; }

        .video-col:nth-child(9) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 2.35s; }

        .video-col:nth-child(10) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 2.55s; }

        .video-col:nth-child(11) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 2.75s; }

        .video-col:nth-child(12) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 2.95s; }


        @keyframes slide-in-left {
            from {
                margin-left: -25%;
                margin-right: 25%;
                opacity: 0; }
            to {
                margin-left: 0;
                margin-right: 0;
                opacity: 1; } }

        @keyframes slide-in-right {
            from {
                margin-left: 25%;
                margin-right: -25%;
                opacity: 0; }
            to {
                margin-left: 0;
                margin-right: 0;
                opacity: 1; } } } }


// .video-modal
//     display: none
//     align-items: center
//     justify-content: center
//     position: absolute
//     top: 0
//     left: 0
//     width: 100%
//     height: 100%
//     background-color: rgba($dark, 0.8)
//     z-index: 99999

//     .video-modal-wrapper
//         display: flex
//         flex-direction: column
//         background-color: $light
//         padding: 4 * $base-width
//         z-index: 999

//         @media only screen and (max-width: #{map-get($breakpoints, l)})
//             padding: 2 * $base-width

//         video
//             max-width: 1200px
//             width: 100%

//         .close-button
//             margin-left: auto
//             margin-bottom: 20px
//             background-color: $light
//             &:hover
//                 background-color: $primary


//     &.open
//         display: flex


.customVid {
    .fancybox-content {
        width: fit-content !important;
        height: fit-content !important; } }
