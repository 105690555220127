.block-projects {
    max-width: $boxed;
    margin: 0 auto;
    padding-bottom: 20*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding-bottom: 17.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-bottom: 15*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-bottom: 10*$base-width; }


    .projekt-grid-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 80px;
        .projekt-container {
            margin: 0;
            width: 100%;
            flex: 0 0 33%;
            padding: 0 10px;
            position: relative !important;
            top: unset !important;
            left: unset !important;
            @media (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 50%; }
            @media (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%; }


            .projekt-picture-container {
                margin: 0;
                height: 350px;
                max-width: 500px;
                width: 100%;
                overflow: hidden;
                img {
                    display: block;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    position: relative; }
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    &:hover {
                        .gallery-overlay {
                            opacity: 1;
                            svg {
                                opacity: 1; } } }
                    .gallery-overlay {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 410px;
                        max-height: 310px;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(77, 127, 147, 0.88);
                        opacity: 0;
                        transition: $transition;
                        z-index: 9;
                        align-items: center;
                        justify-content: center;
                        display: flex;

                        svg {
                            max-width: 5.9rem;
                            width: 100%;
                            max-height: 5.9rem;
                            height: 100%;
                            fill: $light;
                            opacity: 1; } } } }


            .projekt-info-container {
                background-color: #fff;
                padding: 25px 20px;
                p, a {
                    margin: 0;
                    padding: 0;
                    color: $dark;
                    font-size: 1.9rem;
                    line-height: 37px;
                    @media (max-width: #{map-get($breakpoints, xxxl)}) {
                        font-size: 1.8rem;
                        line-height: 36px; }
                    @media (max-width: #{map-get($breakpoints, m)}) {
                        font-size: 1.7rem;
                        line-height: 34px; } }
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline; } }

                .projekt-person--item-container {
                    margin-bottom: 25px;
                    h2 {
                        margin-bottom: 25px;
                        display: flex;
                        align-items: center;
                        color: $dark; } }

                .projekt-info--item-container {
                    a {
                        display: flex;
                        align-items: center; }

                    svg {
                        width: 25px;
                        height: 25px;
                        margin-right: 15px;
                        path {
                            fill: $dark; } } }

                ul {
                    list-style-type: none;
                    padding: 20px 0;

                    li {
                        position: relative;
                        padding-left: 30px;
                        line-height: 1.2;
                        padding-bottom: 2.5*$base-width;
                        &:last-child {
                            padding-bottom: 0; }
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            background-image: url(/wp-content/themes/bergauf/blocks/projects/icons/checkpoint.svg);
                            max-width: 20px;
                            width: 100%;
                            height: 20px;
                            background-size: 20px;
                            margin-top: 2px; } } } } } } }

