/*Variables: Form * */

$success: green;
$error: red;

.block-newsletter {
  .newsletter {
    background: linear-gradient(256deg, #4D7F93 0%, #355460 100%) 0% 0% no-repeat padding-box;
    color: $light;
    text-align: center;
    position: relative;
    .nl-svg-left {
      position: absolute;
      bottom: 0;
      left: 0;
      // opacity: 0
      @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        left: -2%;
        svg {
          max-height: 200px; } }
      @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        left: -5%; }
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        left: -10%;
        svg {
          max-height: 150px; } }
      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        left: -14%; }
      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        left: -25%;
        svg {
          max-height: 115px; } } }
    .nl-svg-right {
      position: absolute;
      bottom: 0;
      right: -2%;
      opacity: 0.1;
      @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        svg {
          max-height: 220px; } }
      @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        right: -8%; }
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        right: -12%;
        svg {
          max-height: 180px; } }
      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        right: -18%; }
      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        right: -35%;
        svg {
          max-height: 110px; } } }
    .wrapper {
      max-width: $small;
      margin: 0 auto;
      padding: 8*$base-width 2.5*$base-width 15*$base-width;
      .headline-nl {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        h1, h2, h3 {
          color: $light;
          margin-bottom: 0; }
        svg {
          padding-bottom: 20px;
          @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            display: none; } } }
      p {
        font-size: 1.2rem;
        line-height: 1.4;
        letter-spacing: 0.2px;
        max-width: 790px;
        margin: 0 auto; }

      .layout_form {
        padding-bottom: 4*$base-width;
        .editable_content {
          display: flex;
          justify-content: space-between;
          @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            flex-direction: column; }
          .cr_form-inputgroup {
            position: relative;
            .cr_form-input {
              height: 60px;
              border-radius: 0;
              padding: 10px 20px;
              font-size: 1.6rem;
              border: unset;
              width: 100%;
              &:focus, &.filled {
                outline: 0;
                + label {
                  transition:  $transition;
                  top: -12px;
                  font-size: 1.4rem;
                  color: $light;
 } } }                  // background-color: $light
            label {
              position: absolute;
              pointer-events: none;
              top: 50%;
              transform: translateY(-50%);
              left: 20px;
              transition: all 0.3s ease-in-out;
              display: inline-block;
              padding-bottom: 0;
              color: $primary;
              text-transform: uppercase;
              font-size: 1.6rem; } }
          .cr_form-component--text {
            flex: 0 0 20%;
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
              flex: 0 0 100%;
              margin: 15px 0; } }
          .cr_form-component--email {
            flex: 0 0 36.454%;
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
              flex: 0 0 100%;
              margin: 15px 0; } }
          .btn.btn-primary {
            background: unset;
            &:hover {
              background: $light; } }
          .cr_form-component--submit {
            flex: 0 0 19%;
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
              flex: 0 0 100%;
              .btn.btn-primary {
                margin: 0 auto;
                margin-top: 10px; } } } } }
      a {
        color: #C4C4C4;
        text-decoration: none;

        // &.in-view
        //   .nl-svg-left
        //     animation-delay: 200ms
        //     animation: slide-in-svg-al 1.5s ease forwards

        //   @keyframes slide-in-svg-al
        //     from
        //         margin-left: -25%
        //         margin-right: 25%
        //         opacity: 0

        //     to
        //         margin-left: 0
        //         margin-right: 0
        //         opacity: 1

        //   .nl-svg-right
        //       animation-delay: 250ms
        //       animation: slide-in-svg 1.5s ease forwards

        //   @keyframes slide-in-svg
        //       from
        //           margin-right: -25%
        //           margin-left: 25%
        //           opacity: 0

        //       to
        //           margin-right: 0
        //           margin-left: 0
 } } } }        //           opacity: 0.1
