.block-services {
  padding-top: 5*$base-width;
  padding-bottom: 20*$base-width;
  @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
    padding-bottom: 17.5*$base-width; }
  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    padding-bottom: 15*$base-width;
    padding-top: 2.5*$base-width; }
  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    padding-bottom: 10*$base-width; }
  .wrapper {
    .text-icons {
      display: flex;
      justify-content: space-between;
      -moz-column-gap: 50px;
      flex-wrap: wrap;
      row-gap: 30px;
      padding-top: 6.25*$base-width;
      @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        row-gap: 50px; }
      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 2.5*$base-width; }
      .serviceitem {
        max-width: 140px;
        display: flex;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
          flex: 1 1 33.33%;
          max-width: 100%; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          flex: 0 0 50%;
          max-width: 100%; }
        .icon-wrap {
          width: 114px;
          height: 114px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23333' stroke-width='2' stroke-dasharray='5%2c 10 ' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 0 0 rgba($primary, 1);
          svg {
            max-width: 65px;
            max-height: 65px;
            padding: 5px;
            width: 100%;
            height: 100%;
            fill: $primary; } }

        p {
          text-align: center;
          text-transform: uppercase;
          padding-top: 15px;
          font-size: 1.6rem;
          line-height: 1.4;
          font-weight: 500;
          @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            font-size: 1.4rem; } } } } }

  &.in-view {
    .serviceitem {
      animation: bottom-top 2s ease forwards; }

    @keyframes bottom-top {
      from {
        margin-bottom: -25%;
        margin-top: 25%;
        opacity: 0; }

      to {
        margin-bottom: 0;
        margin-top: 0;
        opacity: 1; } } } }
