.block-shop-the-look {
  position: relative;
  margin: 0 auto;
  padding-bottom: 18.75*$base-width;
  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    padding-bottom: 12*$base-width; }
  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    padding-bottom: 7.5*$base-width; }

  .product-slider, .product-wrapper {
    padding-top: 6*$base-width;
    position: relative;
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      padding-top: 2.5*$base-width; }
    .owl-stage-outer {
      overflow: hidden;
      .owl-stage {
        display: flex;
        align-items: center;
        justify-content: center; }
      .product_item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 402px;
        width: 338px;
        padding: 0 2.5*$base-width;
        margin: 0 auto;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          width: 350px; }
        .product_img {
          max-width: 350px;
          max-height: 340px;
          width: 100%;
          height: 100%;
          border: 1px solid #E8E8E8;
          img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover; } } }

      a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        text-decoration: none; } }

    .owl-nav {
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        display: none; }
      button {
        border-radius: 50%;
        background-color: $light;
        width: 65px;
        height: 65px;
        border: 1px solid $dark;
        &.owl-prev {
          position: absolute;
          left: -7%; }
        &.owl-next {
          position: absolute;
          right: -7%; }
        &:hover {
          transition: $transition;
          background-color: $primary;
          border: 1px solid $primary;
          svg {
            path {
              transition: $transition;
              fill: $light; } } } } }

    .owl-dots {
      width: 100%;
      display: flex;
      margin-top: 30px;
      justify-content: center;
      align-items: center;
      .owl-dot {
        border-radius: 50%;
        border: 1px solid $primary;
        background-color: $light;
        width: 10px;
        height: 10px;
        margin: 0 5px; }
      .active {
        border: 1px solid $primary;
        background-color: $primary; } } }

  .product-wrapper {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
      justify-content: center; }
    a {
      display: block;
      flex: 0 0 25%;
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        flex: 0 0 50%; }
      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        flex: 0 0 100%; } }

    .product_item {
      justify-content: center;
      flex-direction: column;
      height: 402px;
      padding: 0 2.5*$base-width;
      margin: 0 auto;
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        width: fit-content;
        margin: 2.5*$base-width auto;
        &:last-child {
          margin-bottom: 0; } }
      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        height: 315px; }

      .product_img {
        max-width: 350px;
        max-height: 350px;
        width: 100%;
        height: 100%;
        border: 1px solid #E8E8E8;
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          max-width: 265px;
          max-height: 265px; }
        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover; } } }
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      text-decoration: none; } } }
