.block-catalogs {
    padding: 22.5 * $base-width 0;
    max-width: $wrapped;
    margin: 0 auto;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding: 20*$base-width 0; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding: 15*$base-width 0; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 10*$base-width 0; }

    .katteaser-wrap {
        display: flex;
        flex-wrap: wrap;
        row-gap: 15*$base-width;
        justify-content: space-between;
        .katteaser-inner {
            flex: 0 0 45%;
            display: flex;
            align-items: center;
            position: relative;
            opacity: 0;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 100%; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                flex-direction: column;
                align-items: flex-start; }

            &:hover {
                a.imglink {
                    .img-wrap {
                        transform: rotate(-5deg);
                        transition: $transition; } }
                .btn-secondary {
                    transition: $transition;
                    color: $light;
                    background-color: $primary;
                    border: 2px solid $primary;
                    svg {
                        path {
                            transition: $transition;
                            fill: $light; } } } }


            a.imglink {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                max-width: 250px;

                .img-wrap {
                    overflow: hidden;
                    z-index: -1;
                    position: relative;
                    padding-bottom: 100%;
                    height: 0;
                    transition: $transition;
                    img {
                        position: absolute;
                        max-width: 100%;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        z-index: -2;
                        top: 0;
                        left: 0; } }
                &:hover {
                    .img-wrap {
                        transform: rotate(-5deg);
                        transition: $transition; } } }


            .text-wrap {
                padding-left: 30px;
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    padding-left: 0;
                    width: 100%;
                    padding-top: 30px; }
                p {
                    padding-bottom: 2.5*$base-width; }

                .btn-secondary {
                    padding: 14px 30px 15px 30px;
                    svg {
                        max-width: 30px;
                        max-height: 30px;
                        margin-right: 6px; }
                    &:hover {
                        svg {
                            path {
                                fill: $light; } } } } } } }


    &.in-view {
        .katteaser-inner:nth-child(1) {
            animation: 250ms slide-in-left ease forwards;
            animation-delay: .75s; }

        .katteaser-inner:nth-child(2) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: .95s; }

        .katteaser-inner:nth-child(3) {
            animation: 250ms slide-in-left ease forwards;
            animation-delay: 1.15s; }

        .katteaser-inner:nth-child(4) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 1.35s; }

        .katteaser-inner:nth-child(5) {
            animation: 250ms slide-in-left ease forwards;
            animation-delay: 1.55s; }

        .katteaser-inner:nth-child(6) {
            animation: 250ms slide-in-right ease forwards;
            animation-delay: 1.75s; }


        @keyframes slide-in-left {
            from {
                margin-left: -25%;
                margin-right: 25%;
                opacity: 0; }
            to {
                margin-left: 0;
                margin-right: 0;
                opacity: 1; } }

        @keyframes slide-in-right {
            from {
                margin-left: 25%;
                margin-right: -25%;
                opacity: 0; }
            to {
                margin-left: 0;
                margin-right: 0;
                opacity: 1; } } } }

