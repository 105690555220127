// Base
$base-width: .8rem;

// Fonts
$font-default: 'sofia-pro', sans-serif;
$headline-font: 'Playfair', serif;

$bold: 700;
$fine: 300;

// General
$dark: #000;
$light: #fff;
$grey: #E8E8E8;

// Brand Colors
$primary: #355460;
$secondary: #E3000B;

$hover: #4D7F93;

// Others
$divider: rgba($dark, .3);
$border-radius: 0;
$bg-color: $light;

// Widths
$max: 2560px;
$wrapped: 1920px;
$full: 1730px;
$boxed: 1560px;
$small: 1055px;

// Transitions
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "s": 625px, "m": 768px, "l": 992px, "xl": 1200px, "xxl": 1440px, "xxxl": 1680px);
