.block-appointment-booking {
  position: relative;
  margin: 0 auto;
  padding-top: 22.5 * $base-width;
  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    padding-top: 14.5rem; }
  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    padding-top: 9.7rem; }

  h2.big {
    z-index: 3;
    text-align: center;
    top: -3px;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
      font-size: 18rem;
      top: 15px; }
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
      font-size: 14rem;
      top: 52px; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
      font-size: 10rem;
      top: 53px; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      font-size: 6rem;
      top: 26px; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      font-size: 5rem;
      top: 51px;
      text-align: center; } }

  .inner-wrap {
    background: linear-gradient(256deg, #4D7F93 0%, #355460 100%) 0% 0% no-repeat padding-box;
    position: relative;
    padding: 14.5rem 0;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
      padding: 12.5*$base-width 0; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
      padding: 10*$base-width 0; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      padding: 7.5*$base-width 0; }
    .booking {
      position: relative;
      max-width: 1475px;
      padding: 0 2.5*$base-width 0 0;
      margin: 0 auto;
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        padding: 0 2.5*$base-width; }
      iframe {
        width: 100%;
        height: 60vh; } } } }
