.block-hotspot-teaser {
    padding-top: 22.5 * $base-width;
    padding-bottom: 20*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding-bottom: 17.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-bottom: 10*$base-width;
        padding-top: 11.25*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        padding-bottom: 7.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-bottom: 5px;
        padding-top: 5.4rem; }
    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
        padding-top: 4.5rem; }

    h2.big {
        top: -3px;
        z-index: -1;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            top: 15px; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            top: 52px; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            top: 0; } }

    .fullwrap {
        display: flex;
        max-height: 962px;
        overflow: hidden;
        &.bigr {
            flex-direction: row-reverse; }
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            max-height: 902px; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            max-height: 702px; }
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-wrap: wrap;
            max-height: 100%; } }
    .hotspot {
        flex: 0 0 50%;
        border: 1px solid #C4C4C4;
        background-color: $light;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 100%; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            height: 350px;
            .inner-wrap {
                height: 100%; } }
        .text {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 595px;
            margin: 0 auto;
            padding: 10*$base-width 2.5*$base-width 6.25*$base-width;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                padding: 5*$base-width 2.5*$base-width 2.5*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding: 5*$base-width 2.5*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                height: 100%; }
            a {
                display: inline-block; } }
        .hotspotimage {
            max-width: 950px;
            position: relative;
            margin: 0 auto;
            width: 100%;
            overflow: hidden;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                max-width: 100%; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                display: none; }
            picture {
                display: block;
                height: 605px;
                max-width: 950px;
                width: 100%;
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    height: 460px;
                    max-width: 100%; }
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    height: 100%; }
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    object-position: top; } }
            .anchorpoint_svg {
                z-index: 10;
                cursor: pointer;
                &.show_active {
                    z-index: 20 !important; }
                &:hover {
                    svg {
                        #outline {
                            transition: $transition;
                            fill: #355460; }
                        #center {
                            transition: $transition;
                            fill: #fff; } } } }

            .anchorpoint_modal {
                display: none;
                transition: $transition;
                background-color: $light;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
                border-radius: 30px;
                text-decoration: none;
                padding: 30px 44px;
                z-index: 8;
                cursor: default;
                &.show_active {
                    z-index: 15 !important; }
                .inner {
                    max-width: 280px; }
                h4 {
                    color: $dark;
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        margin-bottom: 1rem; } }
                &:hover {
                    h4 {
                        color: $dark; } }
                &.show_an {
                    display: block;
                    transition: $transition;
                    cursor: pointer; } } } }



    .teaserkacheln {
        display: flex;
        flex: 0 0 50%;
        flex-wrap: wrap;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 100%; }
        .teaser-item {
            flex: 0 0 50%;
            a {
                text-decoration: none; }
            .high {
                height: 480px;
                overflow: hidden;
                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                    height: 450px; }
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    height: 350px; } }
            .content-wrapper {
                position: relative;
                .btn-link {
                    position: absolute;
                    bottom: 5*$base-width;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 5;
                    width: 100%;
                    text-align: center;
                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        bottom: 2.5*$base-width; } } }
            &.full {
                transition: $transition;
                .btn.btn-link {
                    color: $light;
                    svg {
                        fill: $light; } }
                .content-wrapper {
                    &:before {
                        content: '';
                        position: absolute;
                        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 70%, rgba(0,0,0,1) 100%);
                        width: 100%;
                        height: 50%;
                        z-index: 5;
                        bottom: 0;
                        transition: $transition; }
                    img {
                        transition: $transition; } }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    .img-wrap {
                        height: 100%; } }

                &:hover {
                    transition: $transition;
                    .content-wrapper {
                        &:before {
                            transition: $transition;
                            background: linear-gradient(0deg, rgba(53,84,96,1) 0%, rgba(53,84,96,0.8) 50%, rgba(53,84,96,0) 75%); }

                        img {
                            transition: $transition;
                            transform: scale(1.2); }

                        .btn.btn-link {
                            svg {
                                transition: $transition;
                                margin-left: 20px; } } } } }

            &.free {
                border: 1px solid #C4C4C4;
                transition: $transition;
                .img-wrap {
                    padding-top: unset;
                    padding-top: 44px;
                    img {
                        max-width: 225px;
                        position: relative;
                        margin: 0 auto;
                        transition: $transition; }

                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        padding-top: 20px;
                        img {
                            max-width: 180px; } } }

                &:hover {
                    transition: $transition;
                    .img-wrap {
                        img {
                            transition: $transition;
                            transform: rotate(-10deg); } }
                    .btn.btn-link {
                        color: $primary;
                        transition: $transition;
                        svg {
                            transition: $transition;
                            margin-left: 20px;
                            fill: $primary; } } } }

            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                flex: 0 0 100%; } }


        .teaser-item-tworow {
            flex: 0 0 100%;
            transition: $transition;
            a {
                display: flex;
                text-decoration: none;
                .content {
                    flex: 0 0 50%;

                    &.wrap {
                        background: linear-gradient(45deg, rgba(53,84,96,1) 0%, rgba(77,127,147,1) 50%, rgba(53,84,96,1) 100%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        h4 {
                            color: $light;
                            margin-bottom: 4*$base-width;
                            max-width: 320px;
                            text-align: center;
                            line-height: 1.5;
                            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                max-width: 250px; } } } }

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    flex-wrap: wrap;
                    flex-direction: row-reverse;
                    height: 100%;
                    .content {
                        flex: 0 0 100%; } } }
            img {
                transition: $transition; }

            .high {
                height: 480px;
                overflow: hidden;
                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                    height: 450px; }
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    height: 350px; } }

            &:hover {
                transition: $transition;
                img {
                    transition: $transition;
                    transform: scale(1.2); }
                .btn.btn-primary {
                    transition: $transition;
                    background-color: $light;
                    color: $primary; }
                a {
                    transition: $transition;
                    .content {
                        &.wrap {
                            transition: $transition;
                            background: linear-gradient(320deg, rgba(53,84,96,1) 0%, rgba(77,127,147,1) 50%, rgba(53,84,96,1) 100%); } } } } }


        .img-wrap {
            z-index: 2;
            min-height: 100%;
            padding-top: calc(56.25% / 2);
            position: relative;

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 100%;
                width: 100%; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding-top: 0;
                max-height: 350px;
                overflow: hidden;
                min-height: unset; }

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center; } } } }
