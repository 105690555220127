header {
    width: 100%;
    max-width: 100vw;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    background-color: #fff;

    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        pointer-events: auto; }

    &.fixed {
        box-shadow: 0 0.8rem 1.6rem rgba(54, 50, 60, 0.05); }

    .header-inner {
        padding-right: 2.5*$base-width;
        padding-left: 2.5*$base-width;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: $boxed;
        margin: 0 auto;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            padding: 2.5*$base-width; }

        .logo {
            display: inline-flex;
            max-width: 243px;
            max-height: 61px;
            width: 100%;
            height: 100%;
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                max-width: 200px; } }


        .meta-menu {
            display: flex;
            padding: 28px 0 20px;
            justify-content: flex-end;
            .meta-item {
                padding: 0 20px;
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 1.4rem;
                &:last-child {
                    padding-right: 0; }

                &.office_hours {
                    color: $primary; }

                svg {
                    margin-right: 10px;
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        width: 18px;
                        height: 18px; } }

                a {
                    text-decoration: none;
                    display: flex;
                    align-items: center; }
                &:hover {
                    svg {
                        transition: $transition;
                        path {
                            transition: $transition;
                            fill: $secondary; } } }

                &.contact {
                    &:hover {
                        svg {
                            transition: $transition;
                            path {
                                transition: $transition;
                                fill: $secondary;
                                stroke-width: 0.3;
                                stroke: $secondary; } } } }

                &.office_hours {
                    &:hover {
                        svg {
                            path {
                                fill: $primary;
                                stroke: $primary; } } } } }

            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                justify-content: flex-start;
                position: relative;
                flex-wrap: wrap;
                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0%;
                    left: 50%;
                    transform: translateX(-50%);
                    background-image: linear-gradient(to right, #C4C4C4 0%, #C4C4C4 50%, transparent 50%);
                    background-size: 10px 2px;
                    background-repeat: repeat-x;
                    z-index: -1; }

                .meta-item {
                    padding: 10px 20px; } } }





        .main {
            padding-bottom: 35px;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                padding-bottom: 0; }
            nav {
                align-items: center;
                justify-content: flex-end;
                font-size: 1.4rem;
                font-weight: 500;
                height: 100%;
                display: flex;
                flex-direction: column;
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    padding-left: 1.5*$base-width;
                    order: 2;
                    justify-content: flex-end;
                    position: static;
                    font-size: 2rem; }

                .wrap {
                    display: flex;
                    flex-direction: column;
                    transition: $transition;
                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        position: absolute;
                        right: -200%;
                        top: 100px;
                        transition: $transition;
                        background-color: #fff;
                        padding: 5*$base-width 3*$base-width;
                        width: 100%;
                        height: 100vh;
                        justify-content: space-between;
                        flex-direction: column;
                        z-index: 100;
                        overflow-y: scroll;

                        &.open {
                            right: 0; } }
                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        .meta-menu {
                            order: 1; }
                        .main-wrap {
                            order: 0; } }

                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        padding: 2.5*$base-width 0 10*$base-width;
                        top: 89px;
                        height: calc(100vh - 94px); }

                    .main-wrap {
                        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                            padding: 0 2.5*$base-width; } } }

                ul {
                    display: flex;
                    height: 100%;
                    align-items: center;
                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        transition: $transition;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start; }

                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        height: unset; }

                    li {
                        padding: 0 3.75*$base-width;
                        list-style-type: none;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        transition: $transition;
                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                            padding: 1.875*$base-width 0;
                            height: fit-content;
                            display: unset; }

                        &:last-child {
                            padding-right: 0; }
                        a {
                            color: $primary;
                            font-family: $font-default;
                            font-weight: $bold;
                            font-size: 1.6rem;
                            text-transform: uppercase;
                            text-decoration: none; }
                        &:hover, &.active {
                            a {
                                color: $secondary;
                                transition: $transition; }
                            &.children {
                                button {
                                    svg {
                                        path {
                                            fill: $secondary; } } } } }

                        &.children {
                            button {
                                padding-left: 10px;
                                background: none;
                                border: none; }

                            ul.level-1 {
                                display: none; } }


                        &.show {
                            transition: $transition;
                            a {
                                color: $secondary; }
                            button {
                                svg {
                                    path {
                                        transition: $transition;
                                        fill: $secondary; } } }

                            .dropdown-wrapper {
                                background-color: rgba(232, 232, 232, 0.88);
                                width: 100vw;
                                top: 140px;
                                height: calc(100vh - 140px);
                                position: absolute;
                                transition: 0.4s ease-out;
                                right: 0;
                                left: 0;
                                margin-top: 0;
                                z-index: 99999;
                                transition: $transition;
                                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                                    overflow-y: scroll; }
                                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                    position: static;
                                    flex: 0 0 100%;
                                    width: 100%;
                                    padding-top: 2.5*$base-width;
                                    height: unset;
                                    background-color: $light;
                                    top: 0;
                                    left: auto; } }


                            ul.level-1 {
                                display: flex;
                                flex-wrap: wrap;
                                align-items: flex-start;
                                align-content: flex-start;
                                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                    position: relative;
                                    flex-direction: unset;
                                    padding: 0;
                                    right: 0;
                                    top: 0;
                                    min-height: auto;
                                    overflow-y: scroll; }
                                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                    height: 100%;
                                    &:first-child {
                                        padding-top: 10px; }
                                    &:last-child {
                                        padding-bottom: 0; } }

                                li {
                                    max-height: 363px;
                                    display: flex;
                                    padding: 1.5px;
                                    overflow: hidden;
                                    justify-content: center;
                                    align-items: center;
                                    position: relative;
                                    flex: 0 0 25%;
                                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                                        max-height: 250px;
                                        height: 100%; }
                                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                        max-height: 180px; }
                                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                                        flex: 0 0 33.33%; }
                                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                        flex: 0 0 50%;
                                        max-height: 204px; }
                                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                        max-height: 195px; }
                                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                                        flex: 0 0 100%; }


                                    a {
                                        display: block;
                                        height: 100%;
                                        width: 100%;
                                        position: relative;
                                        color: $light;
                                        font-weight: 400;
                                        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                            display: flex;
                                            align-items: center; }
                                        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                            color: $dark; }
                                        .img-wrapper {
                                            margin: 0 auto;
                                            position: relative;
                                            max-height: 360px;
                                            height: 100%;
                                            overflow: hidden;
                                            width: 100%;
                                            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                                                max-height: 165px; }
                                            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                                                max-height: 200px; }
                                            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                                display: none; }


                                            img {
                                                object-fit: cover;
                                                display: block;
                                                width: 100%;
                                                height: 100%;
                                                transition: $transition; }

                                            &:before {
                                                content: '';
                                                position: absolute;
                                                bottom: 0;
                                                width: 100%;
                                                height: 100%;
                                                z-index: 9;
                                                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 70%, rgba(0,0,0,1) 100%); } }

                                        .text-wrapper {
                                            position: absolute;
                                            width: 100%;
                                            text-align: center;
                                            bottom: 20px;
                                            z-index: 10;
                                            svg {
                                                margin-left: 10px;
                                                transition: $transition;
                                                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                                    path {
                                                        fill: $dark; } } }
                                            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                                position: relative;
                                                left: unset;
                                                bottom: unset;
                                                transform: unset;
                                                padding-bottom: 10px;
                                                text-align: left; }
                                            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                                padding-bottom: 20px;
                                                svg {
                                                    width: 15px; } } } }


                                    &:hover, &.active {
                                        transition: $transition;
                                        a {
                                            .img-wrapper {
                                                &:before {
                                                    transition: $transition;
                                                    background: linear-gradient(0deg, rgba(53,84,96,1) 0%, rgba(53,84,96,0.7) 50%, rgba(53,84,96,0) 75%); }

                                                img {
                                                    transition: $transition;
                                                    transform: scale(1.2); } }
                                            .text-wrapper {
                                                svg {
                                                    transition: $transition;
                                                    margin-left: 20px; } } } }

                                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                        &.active {
                                            a {
                                                color: $secondary;
                                                .text-wrapper {
                                                    svg {
                                                        transition: $transition;
                                                        margin-left: 20px;
                                                        path {
                                                            fill: $secondary; } } } } } } } } } } } }





            .navbar-toggler {
                display: none;
                background: transparent;
                border: none;
                .toggle {
                    background: none;
                    border-radius: 100%;
                    height: 50px;
                    width: 50px;
                    position: relative; }

                .line-toggle {
                    height: 2px;
                    background-color: $dark;
                    width: 40px;
                    border-radius: 8px;
                    display: inline-block;
                    position: absolute;
                    margin: 0 auto;

                    &.top {
                        top: 15px;
                        left: 0;
                        right: 0;
                        transition: $transition; }

                    &.middle {
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        right: 0;
                        transition: $transition; }

                    &.bottom {
                        bottom: 15px;
                        left: 0;
                        right: 0;
                        transition: $transition; } }
                &.open {
                    .line-toggle {
                        transition: $transition;
                        background-color: #000;
                        &.middle {
                            opacity: 0; }

                        &.top {
                            top: 50%;
                            transform: rotate(45deg) translateY(-50%);
                            transition: $transition; }

                        &.bottom {
                            top: 50%;
                            bottom: unset;
                            transform: rotate(-45deg) translateY(-50%);
                            transition: $transition; } } }

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    display: flex;
                    align-items: center;
                    z-index: 99; } } } } }


#service-check-image-widget-positioner {
    right: -6px;
    bottom: -16px;
    position: fixed;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        &.mobile-widget {
            position: absolute;
            right: 0;
            bottom: 300px; } }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        &.mobile-widget {
            bottom: 280px; } } }

#service-check-image-widget-positioner svg#sciw-image-8c73193184 {
    display: inline-block !important;
    height: 125px !important;
    max-height: 125px;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        max-height: 80px; } }



#service-check-image-widget-positioner .sciw-finger-icon {
    display: none !important;
    max-height: 0;
    max-width: 0; }

#service-check-image-widget-positioner .sciw-overlay {
    strong {
        font-size: 18px;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            font-size: 16px; } } }


#popup4 {
    height: unset !important;
    background-color: $primary !important;
    .popup4-text {
        line-height: 1.5 !important;
        font-size: 16px !important; }
    .popup4-header {
        align-items: flex-start !important; } }





