.block.block-associate {
    max-width: $wrapped;
    margin: 0 auto;
    padding-bottom: 20*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding-bottom: 17.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-bottom: 10*$base-width; }

    .wrapper {
        padding: 8*$base-width 0;
        flex-direction: column;
        align-items: center;
        display: flex;
        position: relative;
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            padding: 10*$base-width 2.5*$base-width; }
        .headline-wrapper {
            display: flex;
            align-items: baseline;
            padding-bottom: 2.5*$base-width;
            p.subline {
                color: $dark;
                font-family: $headline-font;
                -webkit-text-stroke: 1px $dark;
                font-size: 2.5rem;
                line-height: 40px;
                display: block;
                font-weight: 500;
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    font-size: 2.2rem;
                    line-height: 38px; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    font-size: 2rem;
                    line-height: 35px; } } }
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0%;
            z-index: -1;
            left: 50%;
            transform: translateX(-50%);
            background-image: linear-gradient(to right, #C4C4C4 0%, #C4C4C4 50%, transparent 50%);
            background-size: 10px 2px;
            background-repeat: repeat-x; }

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: -100%;
            z-index: -1;
            left: 50%;
            transform: translateX(-50%);
            background-image: linear-gradient(to right, #C4C4C4 0%, #C4C4C4 50%, transparent 50%);
            background-size: 10px 2px;
            background-repeat: repeat-x; }

        .associate-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            .associateLogo {
                padding: 2.5*$base-width 5*$base-width;
                display: flex;
                align-items: center;
                justify-content: center;
                @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                    padding: 2.5*$base-width 6.25*$base-width; }
                svg {
                    max-height: 70px;
                    width: 170px;
                    margin: 0 auto;
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        width: 100px;
                        max-height: 50px; } } } }

        .associateslider {
            max-width: 1230px;
            .owl-stage-outer {
                overflow: hidden;
                .owl-stage {
                    display: flex; } }
            .owl-item {
                display: flex; }
            .associateLogo {
                padding: 2.5*$base-width 5*$base-width;
                display: flex;
                align-items: center;
                justify-content: center;
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    padding: 1.25*$base-width; }
                svg {
                    max-height: 70px;
                    width: 170px;
                    margin: 0 auto;
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        width: 100px;
                        max-height: 50px; }
                    // @media only screen and (max-width: #{map-get($breakpoints, s)})
                    //     width: 50px
 } }                    //     max-height: 40px
            .owl-dots {
                display: none; }
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                .owl-dots {
                    width: 100%;
                    display: flex;
                    margin-top: 30px;
                    justify-content: center;
                    align-items: center;
                    .owl-dot {
                        border-radius: 50%;
                        border: 1px solid $primary;
                        background-color: $light;
                        width: 10px;
                        height: 10px;
                        margin: 0 5px; }
                    .active {
                        border: 1px solid $primary;
                        background-color: $primary; } } }

            // @media only screen and (max-width: #{map-get($breakpoints, s)})
            //     .owl-dots
            //         .owl-dot
            //             height: 7px
            .owl-nav {
                position: absolute;
                top: 20%;
                transform: translateY(-50%);
                width: 100%;
                display: flex;
                justify-content: space-between;
                button {
                    border-radius: 50%;
                    background-color: $light;
                    width: 55px;
                    height: 55px;
                    border: 1px solid $dark;
                    &.owl-prev {
                        position: absolute;
                        left: -8%; }
                    &.owl-next {
                        position: absolute;
                        right: -8%; }
                    svg {
                        height: 100%; }
                    &:hover {
                        transition: $transition;
                        background-color: $primary;
                        border: 1px solid $primary;
                        svg {
                            path {
                                transition: $transition;
                                fill: $light; } } }
                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        display: none; } } } }



        &.in-view {
            animation: 1.2s slide-in ease forwards;
            animation-delay: 0s;

            @keyframes slide-in {
                from {
                    margin-left: -25%;
                    margin-right: 25%;
                    opacity: 0; }

                to {
                    margin-left: auto;
                    margin-right: auto;
                    opacity: 1; } } } } }
