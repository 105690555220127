.block-hero {
    position: relative;
    max-width: $wrapped;
    margin: 0 auto;
    padding-bottom: 280px;
    height: 100%;
    padding-top: 143px;
    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        padding-bottom: 250px; }
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        padding-top: 12.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        padding-bottom: 220px; }
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-bottom: 200px; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        padding-top: 94px;
        padding-bottom: 180px; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-bottom: 5*$base-width; }

    .bubble {
        position: absolute;
        left: 2%;
        top: 60%;
        display: flex;
        // opacity: 0
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            left: -4%; }
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            width: 280px;
            height: 280px; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            display: none; }

        .bubblebgholder {
            position: relative;
            width: 328px;
            height: 328px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                width: 280px;
                height: 280px; }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background-color: #E8E8E8;
                width: 328px;
                height: 328px;
                border-radius: 50%;
                z-index: -1;
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    width: 280px;
                    height: 280px; } } }


        .bubblebg {
            width: 300px;
            height: 300px;
            border-radius: 50%;
            background-color: $primary;
            margin: auto;
            z-index: 10;
            .inner {
                display: flex;
                flex-direction: column;
                max-width: 180px;
                align-items: center;
                margin: 0 auto;
                height: 100%;
                justify-content: center;
                p {
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 1.4;
                    color: $light;
                    text-align: center; }
                svg {
                    margin-bottom: 5px; }

                .btn {
                    margin-top: 30px;
                    animation: wiggle 2.5s infinite;

                    @keyframes wiggle {
                        0% {
                            transform: rotate(0deg); }
                        80% {
                            transform: rotate(0deg); }
                        85% {
                            transform: rotate(5deg); }
                        95% {
                            transform: rotate(-5deg); }
                        100% {
                            transform: rotate(0deg); } } } }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                width: 260px;
                height: 260px;
                .inner {
                    max-width: 170px;
                    p {
                        font-size: 1.5rem; }
                    svg {
                        max-width: 40px; }
                    .btn {
                        margin-top: 20px; } } } } }

    .wrapper {
        position: relative;
        height: 100%;
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 0; }
        picture {
            padding-top: 39.474%;
            position: relative;
            display: block;
            width: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center; }

            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                padding-top: 35%; }
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                padding-top: 40%; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding-top: 60%; } }

        // Slider
        .hero-slider {
            position: relative;
            display: block;
            .owl-stage-outer {
                overflow: hidden; }
            .slider-item {
                max-width: 1520px;
                position: relative;
                display: block;
                width: 100%;
                height: 100%;

                picture {
                    padding-top: 45%;
                    position: relative;
                    display: block;
                    width: 100%;
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center; }

                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        padding-top: 42%; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        //padding-top: 70%
                        padding-top: 45%; } }


                .sliderimg {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    &:before {
                        content: '';
                        position: absolute;
                        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 70%, rgba(0,0,0,1) 100%);
                        width: 100%;
                        height: 50%;
                        z-index: 5;
                        bottom: 0;
                        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                            height: 80%; }
                        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                            height: 100%; } } }

                .slider_textblock {
                    position: absolute;
                    z-index: 6;
                    bottom: 50px;
                    left: 50%;
                    width: 100%;
                    transform: translateX(-50%);
                    opacity: 0;
                    .slider_text {
                        text-align: center;
                        .h1 {
                            color: $light;
                            @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                                font-size: 2.5rem; } }

                        .btn-primary {
                            margin: 0 auto; } }
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        bottom: 25px; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        padding: 0 2.5*$base-width; } } }

            .owl-nav {
                position: absolute;
                top: 43%;
                transform: translateY(-50%);
                width: 100%;
                display: flex;
                justify-content: space-between;
                button {
                    border-radius: 50%;
                    background-color: $light;
                    width: 65px;
                    height: 65px;
                    border: 1px solid $dark;
                    &.owl-prev {
                        position: absolute;
                        left: -2%; }
                    &.owl-next {
                        position: absolute;
                        right: -2%; }
                    svg {
                        height: 100%; }
                    &:hover {
                        transition: $transition;
                        background-color: $primary;
                        border: 1px solid $primary;
                        svg {
                            path {
                                transition: $transition;
                                fill: $light; } } }
                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        &.owl-prev {
                            left: -1%; }
                        &.owl-next {
                            right: -1%; }
                        width: 55px;
                        height: 55px; } }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    display: none; } }

            .owl-dots {
                display: none;
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    width: 100%;
                    display: flex;
                    margin-top: 20px;
                    justify-content: center;
                    align-items: center;
                    .owl-dot {
                        border-radius: 50%;
                        border: 1px solid $primary;
                        background-color: $light;
                        width: 10px;
                        height: 10px;
                        margin: 0 5px; }
                    .active {
                        border: 1px solid $primary;
                        background-color: $primary; } } } }


        // hero image
        .hero-img {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            &:before {
                content: '';
                position: absolute;
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 70%, rgba(0,0,0,1) 100%);
                width: 100%;
                height: 50%;
                z-index: 5;
                bottom: 0; } }


        .content {
            position: absolute;
            z-index: 6;
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
            opacity: 0;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                bottom: -14px; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                bottom: -4px; }

            .inner {
                .transparenz {
                    font-size: 11rem;
                    margin-bottom: 8px;
                    color: rgba(255, 255, 255, 0.1);
                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        font-size: 9rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                        font-size: 8rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                        font-size: 6rem; }
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        font-size: 4rem;
                        margin-bottom: 0; } } } }


        .down_arrow {
            position: absolute;
            left: 50%;
            transform: translateY(-50%);
            bottom: -14%;
            display: block;
            transition: $transition;
            &:hover {
                transition: $transition;
                bottom: -16%;
                svg {
                    path {
                        transition: $transition;
                        fill: $primary; } } }
            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                bottom: -15%;
                &:hover {
                    bottom: -17%; } }
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                display: none; } } }

    .product_teaser {
        position: absolute;
        right: 0;
        top: 35.5%;
        width: 671px;
        height: 660px;
        .teaser-wrapper {
            display: flex;
            position: relative;
            height: 100%;
            align-items: flex-end;
            &:after {
                content: '';
                width: 100%;
                height: 100%;
                background-color: #E8E8E8;
                position: absolute;
                z-index: -1; }
            .teaser-img {
                position: absolute;
                max-width: 359px;
                left: 40%;
                bottom: -10%;
                z-index: 10;
                opacity: 0;
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    max-width: 300px; }
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    max-width: 220px;
                    left: 50%; }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    max-width: 170px;
                    left: 55%; } }

            .inner {
                z-index: 10;
                padding: 6*$base-width 7*$base-width;
                .content-teaser {
                    max-width: 55%; }
                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    padding: 3.5*$base-width 4.5*$base-width; } } }

        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            top: 44%;
            height: 540px; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            width: 500px;
            height: 500px;
            top: 40%; }
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            width: 450px;
            height: 435px; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            height: 55%; }
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            height: 52%;
            width: 370px; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            display: none; } }



    &.subpage {
        padding-bottom: 21*$base-width;
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            padding-bottom: 12.5*$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding-bottom: 8.75*$base-width; }

        .bubble {
            top: 56%; }

        .wrapper {
            .down_arrow {
                position: absolute;
                left: 50%;
                transform: translateY(-50%);
                bottom: -14%;
                display: block;
                &:hover {
                    bottom: -16%;
                    svg {
                        path {
                            transition: $transition;
                            fill: $primary; } } }

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    bottom: -20%;
                    &:hover {
                        bottom: -22%; } }
                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    display: none; } } } }

    // Animation
    &.in-view {
        .slider_textblock, .content {
            animation: sparkle-in-content 1.5s ease forwards; }

        @keyframes sparkle-in-content {
            0% {
                opacity: 0; }
            50% {
                opacity: 0.5; }
            100% {
                opacity: 1; } }

        .teaser-img {
            animation: furni-free 1.5s ease forwards;
            animation-delay: 1s; }

        @keyframes furni-free {
            from {
                margin-right: -25%;
                margin-left: 25%;
                opacity: 0; }

            to {
                margin-right: 0;
                margin-left: 0;
                opacity: 1; } }


        // .bubble
        //     animation: bubble 1.5s ease forwards

        // @keyframes bubble
        //     from
        //         margin-right: 25%
        //         margin-left: -25%
        //         opacity: 0

        //     to
        //         margin-right: 0
        //         margin-left: 0
 }        //         opacity: 1


    .slider-item, .hero-img {
        -webkit-animation-name: zoomIn;
        animation-name: zoomIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        animation-delay: 500ms;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;

        @-webkit-keyframes zoomIn {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(.3, .3, .3);
                transform: scale3d(.3, .3, .3); }

            50% {
                opacity: 1; } }


        @keyframes zoomIn {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(.3, .3, .3);
                transform: scale3d(.3, .3, .3); }

            50% {
                opacity: 1; } } } }

