.block-employee-sort {
    max-width: $boxed;
    margin: 0 auto;
    padding: 0 2.5*$base-width;
    padding-bottom: 15*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-bottom: 10*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        padding-bottom: 6.25*$base-width; }
    .employee-buttons-inner {
        // display: flex
        // justify-content: space-between
        padding: 50px 0 80px 0;
        @media (max-width: #{map-get($breakpoints, xl)}) {
            flex-direction: row;
            justify-content: space-around; }
        @media (max-width: #{map-get($breakpoints, l)}) {
            padding: 0px 0 80px 0; }
        @media (max-width: #{map-get($breakpoints, s)}) {
            flex-direction: column;
            padding: 0; }

        .employee-button-container {
            h5 {
                font-size: 16px;
                color: $primary;
                padding-left: 10px;
                text-transform: uppercase;
                @media (max-width: #{map-get($breakpoints, xl)}) {
                    cursor: pointer;
                    padding: 15px 25px;
                    background-color: $dark;
                    color: #fff;
                    margin: 10px;
                    border-radius: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center; } }

            @media (max-width: #{map-get($breakpoints, xl)}) {
                &:first-child {
                    padding-bottom: 0px; }
                .open {
                    display: none; } }
            @media (max-width: #{map-get($breakpoints, s)}) {
                &:first-child {
                    padding-bottom: 20px; } } } }

    .employee-buttons-row {
        padding: 15px 15px 15px 0;
        margin: 0;
        display: flex;
        @media (max-width: #{map-get($breakpoints, xl)}) {
            display: block; }
        .employee-filter-button {
            height: 55px;
            background-color: $dark;
            display: flex;
            margin: 10px;
            border-radius: 40px;
            align-items: center;
            justify-content: center;
            width: 100%;
            p {
                padding: 0 1.5rem;
                font-weight: 600 !important;
                font-family: $font-default!important;
                padding-bottom: 0;
                font-size: 1.4rem !important;
                color: $light !important;
                @media (min-width: #{map-get($breakpoints, xxxl)}) {
                    font-size: 1.6rem !important;
                    padding: 0 2.5*$base-width; } }
            &:hover {
                background-color: $primary;
                transition: $transition;
                p {
                    color: $light!important;
                    cursor: pointer; } } }
        .employee-filter-button-active, .is-checked {
            background-color: $primary;
            transition: $transition;
            p {
                color: $light!important; } } }

    .employee-grid-inner {
        display: flex;
        flex-wrap: wrap;
        height: unset !important;
        @media (max-width: #{map-get($breakpoints, xxl)}) {
            justify-content: center; }
        .employee-container {
            margin: 0;
            width: 100%;
            flex: 0 0 25%;
            padding: 0 10px 50px;
            position: relative !important;
            top: unset !important;
            left: unset !important;
            @media (max-width: #{map-get($breakpoints, xxl)}) {
                flex: 0 0 30%; }
            @media (max-width: #{map-get($breakpoints, xl)}) {
                flex: 0 0 50%; }
            @media (max-width: #{map-get($breakpoints, s)}) {
                flex: 0 0 100%; }


            .employee-picture-container {
                position: relative;
                .employee-picture {
                    display: block;
                    max-height: 450px;
                    overflow: hidden;
                    @media (max-width: #{map-get($breakpoints, s)}) {
                        max-height: 350px; } }
                img,picture {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: relative; }

                .mitarbeiter-informationen {
                    display: flex;
                    color: white;
                    .info-name {
                        background-color: $primary;
                        display: block;
                        position: absolute;
                        top: 10px;
                        font-size: 16px;
                        padding: 10px 25px;
                        font-family: $font-default;
                        text-transform: uppercase;
                        margin-left: -15px;
                        @media (max-width: #{map-get($breakpoints, m)}) {
                            font-size: 14px;
                            font-family: $font-default; }
                        @media (max-width: #{map-get($breakpoints, s)}) {
                            font-size: 11px;
                            font-family: $font-default; } }

                    .info-zusatz {
                        background-color: $primary;
                        display: block;
                        position: absolute;
                        top: 65px;
                        font-size: 10px;
                        padding: 10px 25px;
                        font-family: $font-default;
                        text-transform: uppercase;
                        margin-left: -15px;
                        @media (max-width: #{map-get($breakpoints, xxl)}) {
                            font-size: 12px; }
                        @media (max-width: #{map-get($breakpoints, s)}) {
                            font-size: 14px; } }

                    .info-bereich {
                        background-color: $primary;
                        display: block;
                        position: absolute;
                        bottom: 65px;
                        font-size: 12px;
                        padding: 10px 25px;
                        text-transform: uppercase;
                        font-family: $font-default;
                        right: 0;
                        @media (max-width: #{map-get($breakpoints, s)}) {
                            font-size: 14px; } }
                    .info-standort {
                        background-color: $primary;
                        display: block;
                        position: absolute;
                        bottom: 10px;
                        font-size: 12px;
                        padding: 10px 25px;
                        text-transform: uppercase;
                        font-family: $font-default;
                        right: 0;
                        @media (max-width: #{map-get($breakpoints, s)}) {
                            font-size: 14px; } } } }




            .employee-info-container {
                background-color: #fff;
                padding: 25px 0;
                p, a {
                    margin: 0;
                    padding: 0;
                    color: $dark;
                    font-size: 1.9rem;
                    line-height: 37px;
                    @media (max-width: #{map-get($breakpoints, xxxl)}) {
                        font-size: 1.8rem;
                        line-height: 36px; }
                    @media (max-width: #{map-get($breakpoints, m)}) {
                        font-size: 1.7rem;
                        line-height: 34px; } }
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline; } }

                .employee-person--item-container {
                    h4 {
                        font-size: 2.8rem;
                        margin-bottom: 0;
                        @media (max-width: #{map-get($breakpoints, xxl)}) {
                            font-size: 2.5rem; }
                        @media (max-width: #{map-get($breakpoints, l)}) {
                            font-size: 2.2rem; }
                        @media (max-width: #{map-get($breakpoints, m)}) {
                            font-size: 2rem; } }
                    p {
                        font-size: 1.6rem; } }


                .contact-info {
                    margin-top: 15px; }
                .employee-info--item-container {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 25px;
                        height: 25px;
                        margin-right: 15px;
                        path {
                            fill: $dark; } } } } } } }
