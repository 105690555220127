.block-feedback-slider {
    background-color: #E8E8E8;
    padding: 17*$base-width 2.5*$base-width;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        padding:  10*$base-width 2.5*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 5*$base-width 2.5*$base-width; }
    .wrapper {
        padding: 0 2.5*$base-width;
        position: relative;
        z-index: 2;
        max-width: $boxed;
        margin: 0 auto;

        @media (max-width: #{map-get($breakpoints, xl)}) {
            padding: 0; } }

    .headline-wrapper + .ratingslider {
        margin-top: 6*$base-width;
        @media (max-width: #{map-get($breakpoints, xl)}) {
            margin-top: 3.125*$base-width; } }

    .ratingslider {
        .owl-stage-outer {
            padding-top: 6*$base-width;

            @media (max-width: #{map-get($breakpoints, m)}) {
                padding-top: 3.5*$base-width; } }

        .owl-stage {
            display: flex; }

        .ratingslider__item {
            display: flex;
            height: 100%;
            position: relative;
            padding: 2.5*$base-width 10*$base-width;
            transition: $transition;

            @media (max-width: #{map-get($breakpoints, s)}) {
                padding: 2.5*$base-width 5*$base-width 2.5*$base-width 6.25*$base-width; }

            .zitat-svg {
                position: absolute;
                left: 5px;
                top: 0;
                svg {
                    path {
                        fill: $primary; } } }

            .ratingslider__item-headline {
                p {
                    font-weight: 600;
                    padding-bottom: 1.25*$base-width; } }

            .rating-stars {
                padding-top: 1.25*$base-width;

                svg {
                    width: 100px;
                    margin-right: 2 * $base-width;

                    path {
                        fill: #E8E8E8;
                        stroke: $primary; } }

                &.fill-1 {
                    svg {
                        .star-1 {
                            fill: $primary; } } }

                &.fill-2 {
                    svg {
                        .star-1, .star-2 {
                            fill: $primary; } } }

                &.fill-3 {
                    svg {
                        .star-1, .star-2, .star-3 {
                            fill: $primary; } } }

                &.fill-4 {
                    svg {
                        .star-1, .star-2, .star-3, .star-4 {
                            fill: $primary; } } }

                &.fill-5 {
                    svg {
                        .star-1, .star-2, .star-3, .star-4, .star-5 {
                            fill: $primary; } } } } }

        .owl-nav {
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            width: 100%;
            display: flex;
            justify-content: space-between;
            button {
                border-radius: 50%;
                background-color: $light;
                width: 65px;
                height: 65px;
                border: 1px solid $dark;
                &.owl-prev {
                    position: absolute;
                    left: -8%; }
                &.owl-next {
                    position: absolute;
                    right: -8%; }
                svg {
                    height: 100%; }
                &:hover {
                    transition: $transition;
                    background-color: $primary;
                    border: 1px solid $primary;
                    svg {
                        path {}
                        transition: $transition;
                        fill: $light; } }
                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                    display: none; } } }

        .owl-dots {
            width: 100%;
            display: flex;
            margin-top: 30px;
            justify-content: center;
            align-items: center;
            .owl-dot {
                border-radius: 50%;
                border: 1px solid $primary;
                background-color: $light;
                width: 10px;
                height: 10px;
                margin: 0 5px; }
            .active {
                border: 1px solid $primary;
                background-color: $primary; } } } }
